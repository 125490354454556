import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../Utils/Hooks/useAuth";
import { roleMap } from "../Utils/transform";
const RequireAuth = ({ allowedRoles, redirectPath = "/login" }) => {
  const { user } = useAuth();
  const location = useLocation();
  return user && allowedRoles?.includes(user.role) ? (
    <Outlet />
  ) : user ? (
    <Navigate
      to={"/" + roleMap[user.role]}
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate to="/homepage" state={{ from: location }} replace />
  );
};

export default RequireAuth;
