import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, Form, Dropdown, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../../../Utils/api";
import { useLang } from "../../../Utils/Context/LangContext";
import { getCountries } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import Input from "react-phone-number-input/input";
import { transformDate } from "../../../Utils/transform";

const ModalEditNewMuslim = ({ show, handleClose, data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("test123");
  const [cpassword, setCPassword] = useState("");
  const [age, setAge] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [audio, setAudio] = useState(null);
  const [religion, setReligion] = useState("");
  const [fb, setFb] = useState("");
  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!language.length || !country.length || !religion.length) {
      return toast.error(t("Please fill all the fields"));
    }
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("age", age);
      formData.append("phone_number", phone_number);
      formData.append("country", country);
      formData.append("language", language);
      formData.append("role", "NEW_MUSLIM");
      formData.append("fb_link", fb);
      formData.append("religion", religion);
      formData.append("gender", gender);
      formData.append("date_creation", date);

      if (audio) formData.append("chahada", audio);
      await api.post(`user/update/${data._id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };
  const { t } = useLang();

  useEffect(() => {
    setName(data.name);
    setEmail(data.email);
    setAge(data.age);
    setCountry(data.country);
    setPhoneNumber(data.phone_number);
    setGender(data.gender);
    setFb(data.fb_link);
    setReligion(data.religion);
    setLanguage(data.language);
    if (data.date_creation) {
      var curr = new Date(data.date_creation);
      var date = curr.toISOString().substring(0, 10);
      setDate(date);
    } else {
      var curr = new Date(data.createdAt);
      var date = curr.toISOString().substring(0, 10);
      setDate(date);
    }
  }, []);

  return (
    <Modal show={show} onHide={handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">{t("Edit New Muslim")}</h4>
        <Form className="form-add" onSubmit={handleSubmit} autoComplete="off">
          <div className="upload-photo"></div>
          <Row className="justify-content-evenly">
            <Form.Group controlId="name" className="col-md-6 mb-3">
              <Form.Control
                type="text"
                placeholder={t("username muslim")}
                className="input-styled"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="name" className="col-md-6 mb-3">
              <Input
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                // country={country}
                placeholder="أدخل رقم الهاتف"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e)}
                name="phone_number"
                className="input-styled "
                style={{
                  // direction: "ltr",
                  width: "100%",
                  fontSize: "20px",
                }}
              />
            </Form.Group>

            {/* <Form.Group controlId="email" className=" col-md-6 mb-3">
              <Form.Control
                type="email"
                placeholder={t("Email")}
                className="input-styled "
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </Form.Group> */}
          </Row>
          {/* <Row className="justify-content-evenly">
            <Form.Group
              controlId="formGridPassword"
              className="col-md-6 mb-3"
              aria-autocomplete="none">
              <Form.Control
                type="password"
                placeholder={t("Password")}
                className="input-styled "
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group
              controlId="formGridConfirmation"
              className="col-md-6 mb-3"
            >
              <Form.Control
                type="password"
                placeholder={t("Confirmation")}
                className="input-styled "
                onChange={(e) => setCPassword(e.target.value)}
                value={cpassword}
                required
              />
            </Form.Group>
          </Row> */}
          <Row className="justify-content-between">
            <Col sm={6}>
              <Form.Group controlId="formGridAge" className=" mb-3">
                <Form.Control
                  type="number"
                  placeholder={t("Age")}
                  className="input-styled "
                  onChange={(e) => setAge(e.target.value)}
                  value={age}
                  required
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Dropdown
                className="dropdown-add mb-3"
                onSelect={(e) => setCountry(e)}
              >
                <Dropdown.Toggle
                  variant="success"
                  id="country"
                  bsPrefix="d-flex justify-content-between align-items-center"
                >
                  <span>{country || t("Country")}</span>
                  <i className="fas fa-chevron-down mt-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="">
                  {getCountries().map((country, index) => (
                    <Dropdown.Item eventKey={en[country]}>
                      {en[country]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col sm={6}>
              <Dropdown
                className="dropdown-add mb-3"
                onSelect={(e) => setGender(e)}
              >
                <Dropdown.Toggle
                  variant="success"
                  id="gender"
                  bsPrefix="d-flex justify-content-between align-items-center"
                >
                  <span>{gender || t("Gender")}</span>
                  <i className="fas fa-chevron-down mt-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {["Male", "Female"].map((gender, index) => (
                    <Dropdown.Item eventKey={gender} key={gender}>
                      {t(gender)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="formGridAge" className=" mb-3">
                <Form.Control
                  type="text"
                  placeholder={t("fb_link")}
                  className="input-styled "
                  onChange={(e) => setFb(e.target.value)}
                  value={fb}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col sm={6}>
              <Dropdown
                className="dropdown-add mb-3"
                onSelect={(e) => setReligion(e)}
              >
                <Dropdown.Toggle
                  variant="success"
                  id="religion"
                  bsPrefix="d-flex justify-content-between align-items-center"
                >
                  <span>{religion || t("religion")}</span>
                  <i className="fas fa-chevron-down mt-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* array of religion */}
                  {
                    //array of religions
                    ["Islam", "Christianity", "Judaism"].map(
                      (religion, index) => (
                        <Dropdown.Item eventKey={religion} key={religion}>
                          {t(religion)}
                        </Dropdown.Item>
                      )
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={6}>
              <Dropdown
                className="dropdown-add mb-3"
                onSelect={(e) => setLanguage(e)}
              >
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  bsPrefix="d-flex justify-content-between align-items-center"
                >
                  <span>{language || t("Language")}</span>
                  <i className="fas fa-chevron-down mt-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="ar">{t("arabic")}</Dropdown.Item>
                  <Dropdown.Item eventKey="en">{t("english")}</Dropdown.Item>
                  <Dropdown.Item eventKey="fr">{t("french")}</Dropdown.Item>
                  <Dropdown.Item eventKey="es">{t("spanish")}</Dropdown.Item>
                  <Dropdown.Item eventKey="filiipino">
                    {t("filiipino")}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="sw">{t("swahili")}</Dropdown.Item>
                  <Dropdown.Item eventKey="pt">
                    {t("portugueuse")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Form.Group controlId="formGridAge" className="mb-3 ">
              <Form.Control
                type="file"
                className="input-styled "
                accept={"mp3"}
                onChange={(evt) => setAudio(evt.target.files[0])}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="formGridAge" className=" ">
              <Form.Control
                type="date"
                className="input-styled "
                onChange={(evt) => setDate(evt.target.value)}
                defaultValue={date}
              />
            </Form.Group>
          </Row>
          <div className="footer-modal">
            <Button
              variant="default"
              className="btn-return mx-4"
              onClick={handleClose}
            >
              {t("Return")}
            </Button>
            <Button variant="primary" className="btn-add mx-4" type="submit">
              {t("Add")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default ModalEditNewMuslim;
