import React, { useState } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";
import { useEffect } from "react";
import FilterModal from "../../shared/filterModal";
import { api } from "../../../Utils/api";
import { transformDate } from "../../../Utils/transform";
import { useLang } from "../../../Utils/Context/LangContext";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";

const ColSound = ({ user }) => {
  return (
    <td>
      {user.chahada ? (
        <ReactAudioPlayer
          style={{ height: "40px" }}
          src={user.chahada}
          controls
        />
      ) : null}
    </td>
  );
};

const NewMuslims = () => {
  const [users, setUsers] = useState([]);
  const { t } = useLang();
  const [filtredData, setFiltredData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [search, setSearch] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [isRequesting, setRequesting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);

  const titles = [
    "#",
    "الاسم",
    "الدولة",
    "العمر",
    "الجنس",
    "الدين",
    "اللغة",
    "الداعية",
    "رقم الهاتف",
    "الفيسبوك",
    "الشهادة",
    "المعلم",
    "التاريخ",
  ];

  async function getData(page) {
    setLoading(false);
    setRequesting(true);
    // Create a new cancel token for the API request
    const source = axios.CancelToken.source();
    try {
      const response = await api.get("users", {
        params: {
          role: "NEW_MUSLIM",
          search,
          page,
        },
        cancelToken: source.token, // Assign the cancel token to the request
      });
      const { data, currentPage, totalPages, totalUsers } = response.data;

      setFiltredData(data);
      setUsers(data);

      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalCount(totalUsers);
    } catch (error) {
      if (axios.isCancel(error)) {
        // Ignore canceled requests
        console.log("Request canceled", error.message);
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
      setRequesting(false);
    }
  }

  useEffect(() => {
    getData(currentPage);
    return () => {
      // Cancel the API request when the component unmounts
      if (cancelToken) {
        cancelToken.cancel("Request canceled");
      }
    };
  }, [currentPage,search]);

  // Pagination event handlers
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const usersPerPage = 10; // Number of users to display per page

  // Calculate the line number based on the current page and users per page
  const calculateLineNumber = (index) => {
    return (currentPage - 1) * usersPerPage + index + 1;
  };

  // Pagination event handlers
  const goToPreviousPage = () => {
    if (!isRequesting && currentPage > 1) {
      // Add check for isRequesting
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (!isRequesting && currentPage < totalPages) {
      // Add check for isRequesting
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="students education content-grid">
        <div className="listStudent listDialogue">
          <div className="header-page">
            <Container>
              <div style={{ flexWrap: "wrap" }} className="content-header-page">
                <h2 className="title-page">{t("New Muslims Data")}</h2>
                <div className="d-flex gap-4 align-items-center">
                  <h4>المجموع: {totalCount}</h4>
                </div>
              </div>
            </Container>
          </div>
          <div className="list-box">
            <Container>
              <Form.Control
                type="text"
                placeholder={t("search")}
                className="input-styled "
                style={{maxWidth:"350px",marginBottom:"20px"}}
                onChange={(e) => setSearch(e.target.value)}
              />

              <Table striped bordered hover responsive>
                <thead>
                  <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                    {titles.map((t) => (
                      <th
                        style={{
                          fontSize: "0.7rem",
                        }}
                        className="text-center  "
                      >
                        <p
                          className="text-truncate text-capitalize"
                          style={{
                            margin: "0 auto",
                          }}
                        >
                          {t}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading
                    ? "loading"
                    : filtredData.map((user, index) => {
                        return (
                          <tr key={`muslim-${index}`}>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {calculateLineNumber(index)}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.name}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.country}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.age}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.gender}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.religion}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.language}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.referer}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <a
                                href={`tel:+${user.phone_number}`}
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                <span
                                  style={{
                                    direction: "rtl",
                                  }}
                                >
                                  {user.phone_number}+
                                </span>
                              </a>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                الفيسبوك:{" "}
                                <button
                                  style={{
                                    background: "#d2e8ff",
                                    height: 22,
                                    width: 80,
                                    borderRadius: 10,
                                    border: "none",
                                    fontSize: 14,
                                    color: "gray",
                                  }}
                                >
                                  <a
                                    href={user.fb_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Visit
                                  </a>
                                </button>
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <ColSound user={user} />
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.haveTeacher}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.date_creation
                                  ? transformDate(user.date_creation)
                                  : transformDate(user.createdAt)}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
              <div className="pagination">
                <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
                  Previous
                </Button>
                <span>{`Page ${currentPage} of ${totalPages}`}</span>
                <Button
                  disabled={currentPage === totalPages}
                  onClick={goToNextPage}
                >
                  Next
                </Button>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewMuslims;
