import React from "react";
import RecentPost from "../../../Components/global/RecentPost";
import BoxAdd from "../../../Components/global/BoxAdd";
import { TITLE_ROUTE_MOARIF } from "../../../Utils/Constants";
import "./Home.scss";

export default function Home() {
  return (
    <div className="homepage">
      <BoxAdd />
      <RecentPost role={TITLE_ROUTE_MOARIF} all={true} />
    </div>
  );
}
