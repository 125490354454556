import React, { useState } from "react";
import { Container, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ImgLogo from "../../Layouts/images/global/logo.png";
import { api } from "../../Utils/api";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isloading, setisloading] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    //emailRegex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!email) {
      return toast.warn("Email field is required");
    }
    if (!emailRegex.test(email)) {
      return toast.warn("Invalid email adress");
    }

    if (!email || !emailRegex.test(email)) return;
    try {
      setisloading(true);
      const { data } = await api.post("/user/request-reset-password", {
        email,
      });
      toast.success("check your email");
      console.log(data);
      setisloading(false);
    } catch (e) {
      setisloading(false);
      toast.error(e?.response.data);
    }
  };
  return (
    <div className="account">
      <Container>
        <div className="login-page">
          <div className="header-login">
            <img src={ImgLogo} alt="منصة بلاغ الدعوية" />
            <h2 className="title-account">منصة بلاغ</h2>
          </div>
          <form className="form-login">
            <h2 className="title-login">Enter your account email</h2>
            <div className="search box-mail">
              <Form.Control
                className={"input-search"}
                placeholder="Email"
                type="email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {!isloading ? (
              <div className="action-btn">
                <Link to="/login" className="link-register btn">
                  login
                </Link>
                <button onClick={handleLogin} className="btn-enter">
                  Enter
                </button>
              </div>
            ) : (
              <div style={{ marginTop: 10 }} className="login-spinner">
                {[...Array(7).keys()].map((key) => (
                  <Spinner
                    size="sm"
                    key={key}
                    animation="grow"
                    style={{ color: "#00376f" }}
                  />
                ))}
              </div>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ForgetPassword;
