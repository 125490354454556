import React from "react";
import { useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { api } from "../../../Utils/api";
import { useLang } from "../../../Utils/Context/LangContext";
import HttpHandlerHoc from "../../../Utils/HOCS/httpHandlerHoc";
import { cleanNumber, getDay, useRoleFromLoc } from "../../../Utils/transform";
import LIST_LINKS from "./LIST_LINKS";
import { MONTHS } from "../../../Utils/Constants";
const MonthAct = () => {
  const ROLE = useRoleFromLoc();
  const { year, month } = useParams();
  const { language } = useLang();
  const [data, setData] = React.useState([]);
  useEffect(() => {
    (async () => {
      const { data } = await api.get("activity", {
        params: {
          year,
          month,
        },
      });
      setData(data.weeks);
    })();
  }, [year, month]);
  const { t } = useLang();

  return (
    <Container
      className="mt-5 rounded px-0"
      style={{ backgroundColor: "white" }}
    >
      <>
        <Table striped bordered responsive>
          <thead>
            <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
              <th
                style={{
                  fontSize: "0.7rem",
                }}
                className="text-center  "
              >
                <p
                  className="text-truncate text-capitalize"
                  style={{
                    margin: "0 auto",
                  }}
                >
                  {t("month") + ":  " + MONTHS[language][month - 1]}
                </p>
              </th>
              {LIST_LINKS[ROLE].map(({ title }, index) => (
                <th
                  key={`da3iya_${title[language]}_${index}`}
                  style={{
                    fontSize: "0.7rem",
                  }}
                  className="text-center  "
                >
                  <p
                    className="text-truncate"
                    style={{
                      margin: "0 auto",
                    }}
                  >
                    {title[language]}
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={`da3iya_${item._id}_${index}`}>
                {/* <td
                  style={{
                    fontSize: "0.7rem",
                  }}
                  className="text-center"
                >
                  <p
                    className="text-truncate"
                    style={{
                      margin: "0 auto",
                    }}
                  >
                    {item.date_creation
                      ? getDay(item.date_creation)
                      : getDay(item.createdAt)}
                  </p>
                </td> */}
                {LIST_LINKS[ROLE].map(({ name, value }, index) => {
                  const activity = item.activities.find(
                    (act) => act.name === name
                  );
                  const activityValue =
                    typeof activity?.value === "object"
                      ? activity?.value.length
                      : activity?.value;
                  return (
                    <td
                      key={`da3iya_${item._id}_${name}_${index}`}
                      style={{
                        fontSize: "0.8rem",
                      }}
                      className="text-center  "
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {activityValue}
                      </p>
                    </td>
                  );
                })}
              </tr>
            ))}
            <tr style={{ backgroundColor: "#cd8100", color: "white" }}>
              <td
                style={{
                  fontSize: "0.7rem",
                }}
                className="text-center"
              >
                <p
                  className="text-truncate text-capitalize"
                  style={{
                    margin: "0 auto",
                    fontWeight: "bold",
                  }}
                >
                  {t("total")}
                </p>
              </td>

              {LIST_LINKS[ROLE].map(({ name, value }, index) => {
                const activity = data.reduce((acc, item) => {
                  const activity = item.activities.find(
                    (act) => act.name === name
                  );

                  const activityValue =
                    typeof activity?.value === "object"
                      ? activity?.value.length
                      : isNaN(parseInt(activity?.value))
                      ? activity?.value + ", "
                      : parseInt(activity?.value);
                  return acc + activityValue;
                }, 0);
                return (
                  <td
                    key={`total_${name}_${index}`}
                    style={{
                      fontSize: "0.8rem",
                    }}
                    className="text-center  "
                  >
                    <p
                      className="text-truncate"
                      style={{
                        margin: "0 auto",
                      }}
                    >
                      {cleanNumber(activity.toString())}
                    </p>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </>
    </Container>
  );
};

export default MonthAct;
