import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const Education = ({
  nextStep,
  handleFormData,
  prevStep,
  values,
  handleInputArray,
}) => {
  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to next step
    if (values.degree === "" || values.lang === []) {
      toast.error("fill the blanks!");
    } else {
      nextStep();
    }
  };

  return (
    <div>
      <div className="education steps-register info">
        <h3 className="title-form">Education</h3>
        <Form className="form-basic">
          <Row>
            <Col>
              <div className="search box-degree">
                <Form.Select
                  aria-label="Default select example"
                  className="input-search"
                  onChange={handleFormData("degree")}
                >
                  <option>School degree</option>
                  <option value="Associate degree">Associate degree</option>
                  <option value="Bachelor’s degree">Bachelor’s degree</option>
                  <option value="Master’s degree">Master’s degree</option>
                  <option value="Doctoral degree">Doctoral degree</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="search box-lang">
                <Form.Select
                  aria-label="Default select example"
                  className="input-search"
                  onChange={handleInputArray("language")}
                >
                  <option>Language</option>
                  <option value="ar">Arabic</option>
                  <option value="fr">Frensh</option>
                  <option value="en">English</option>
                  <option value="pt">Portugueuse</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="default" className="btn-add-lang">
                <i className="fa fa-plus-circle"></i>
                Add Language
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="action-btn">
        <Button
          variant="default"
          className="btn-back btn-step"
          onClick={prevStep}
        >
          <i className="fa fa-angle-left"></i> Back
        </Button>
        <Button
          onClick={submitFormData}
          variant="default"
          type="submit"
          className="btn-next btn-step"
        >
          Next <i className="fa fa-angle-right"></i>
        </Button>
      </div>
    </div>
  );
};

export default Education;
