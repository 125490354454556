import React from 'react';
import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'

const UpdatePhoto = ({ show, handleCloseEditPhoto, onEditFile }) => {
  const [file, setfile] = useState("");
  const handlechangeInput = (e) => {
    console.log(e.target.files[0]);
    setfile(e.target.files[0]);
  };
  return (
    <Modal className="updatePhoto" show={show} onHide={handleCloseEditPhoto}>
      <Modal.Header closeButton className="text-center">
        <Modal.Title className="w-100 title-header">Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form-upload">
          <label className="box-file">
            <Form.Control type="file" id="input-file" className="input-file" onChange={handlechangeInput} />
            <span>{file?.name || "Import an Image"}</span>
            <i className={`fa fa-picture-o icon-file`}></i>
          </label>
        </Form>
      </Modal.Body>
      <Modal.Footer className="action-btn m-0">
        <Button variant="default" className="btn-cancel" onClick={handleCloseEditPhoto}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="btn-post"
          onClick={() => {
            handleCloseEditPhoto();
            if(onEditFile)
              onEditFile(file);
          }}
        >
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePhoto