import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useLang } from "../../../Utils/Context/LangContext";

export default function ModalVideos({visible,handleClose,videos}){
  const { t } = useLang();
  
  return (
    <Modal show={visible} onHide={handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">{t("List of videos")}</h4>
        <div className="d-flex flex-row justify-content-center gap-3 flex-wrap">
          {videos.map((videoSrc, index) => (
            <div>
              <video width="320" height="240" controls>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
