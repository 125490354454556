import React, { useState } from "react";
import { Button, Modal, Form, Dropdown, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../../../Utils/api";
import { useLang } from "../../../Utils/Context/LangContext";

export default function ModalAddGraduateStudent(props) {
  const { t } = useLang();
  const [formValues, setFormValues] = useState({
    name: "",
    islam_date: "",
    country: "",
    images: [],
    videos: [],
    audios: [],
  });

  const setValues = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: files,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formValues.name || !formValues.islam_date || !formValues.country) {
      toast.error(t("All fields are required"));
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", formValues.name);
      formData.append("islamDate", formValues.islam_date);
      formData.append("country", formValues.country);
      for (let i = 0; i < formValues.images.length; i++) {
        formData.append("images", formValues.images[i]);
      }
      for (let i = 0; i < formValues.videos.length; i++) {
        formData.append("videos", formValues.videos[i]);
      }
      for (let i = 0; i < formValues.audios.length; i++) {
        formData.append("audios", formValues.audios[i]);
      }
      console.log(formData);

      const data = await api.post("khirrij/add", formData);
      if (data.status === 201) {
        toast.success(t("Record added successfully"));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("Failed to add record"));
    }
    props.handleClose();
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">{t("Add New Graduate")}</h4>
        <Form className="form-add p-4" onSubmit={handleSubmit}>
          <div className="upload-photo"></div>
          <Row>
            <Form.Group className=" col-md-12 mb-3">
              <Form.Control
                type="text"
                className="input-styled"
                onChange={setValues}
                value={formValues.name}
                title={t("student name")}
                name="name"
                placeholder={t("Enter student name")}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className=" col-md-12 mb-3">
              <Form.Control
                type="date"
                onChange={setValues}
                value={formValues.islam_date}
                title={t("islam date")}
                name="islam_date"
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className=" col-md-12 mb-3">
              <Form.Control
                type="text"
                className="input-styled"
                onChange={setValues}
                value={formValues.country}
                title={t("Country")}
                name="country"
                placeholder={t("Enter country")}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className=" col-md-12 mb-3">
              <Form.Control
                type="file"
                className="bg-white"
                onChange={handleFileChange}
                title={t("images")}
                accept="image/*"
                name="images"
                multiple
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className=" col-md-12 mb-3">
              <Form.Control
                type="file"
                className="bg-white"
                onChange={handleFileChange}
                title={t("videos")}
                accept="video/*"
                name="videos"
                multiple
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className=" col-md-12 mb-3">
              <Form.Control
                type="file"
                className="bg-white"
                onChange={handleFileChange}
                title={t("audios")}
                accept="audio/*"
                name="audios"
                multiple
                required
              />
            </Form.Group>
          </Row>
          <div className="footer-modal">
            <Button
              variant="default"
              className="btn-return mx-4"
              onClick={props.handleClose}
            >
              {t("Return")}
            </Button>
            <Button variant="primary" className="btn-add mx-4" type="submit">
              {t("Add")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
