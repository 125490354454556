import React from "react";
import imgDialoge from "../../Layouts/images/dialogue.png";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ItemCourse = ({ course }) => {
  return (
    <div className="column-dialogue col-lg-6 col-md-6 col-sm-12">
      <Card>
        <Link to={course._id}>
          <Card.Img className="media-dialogue" variant="top" src={imgDialoge} />
        </Link>
        <Card.Body>
          <div className="content-body">
            <Link to={course._id}>
              <Card.Title>{course.title}</Card.Title>
            </Link>
          </div>
          <Link to={course._id} className="btn btn-default btn-view">
            <i className="fa fa-eye"></i>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ItemCourse;
