import React from "react";
import { Outlet } from "react-router";
import "./Activity.scss";

export default function Activity() {
  return (
    <div className="activity">
      <Outlet />
    </div>
  );
}
