import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import channelImage from "../../../assets/channel.jpg";
import "./channel.scss";
import ReactPlayer from "react-player";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { api } from "../../../Utils/api";

export default function Channel() {
  const [isLoading, setIsLoading] = useState(true);
  const [audios, setAudios] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        const res = await api.get("channel");
        if (res.status == 200) {
          setAudios(res.data.audios);
          setImages(res.data.images);
          setVideos(res.data.videos);
          setComments(res.data.comments);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    getData();
  }, []);
  if (isLoading) {
    return <div>loading</div>;
  } else {
    return (
      <div className="channel">
        <div className="channel-header">
          <div className="channel-header-bloc">
            <h2 className="title">تعريف القناة:</h2>
            <p className="description">
            هي قناة توثق بها المقاطع الخاصة بالمسلمين الجدد أثناء نطق الشهادة أو قراءة الفاتحة أو قصار السور أو تعليم الوضوء او الصلاه... الخ
            </p>
            
          </div>
          <div className="channel-header-bloc">
            <img src={channelImage} className="channel-img" />
          </div>
        </div>
        <div className="devider"></div>
        <div className="channel-container">
          {audios.length > 0 && (
            <div className="audio-container">
              <h2 className="title">الصوتيات:</h2>
              <div className="audio-content">
                {audios.map((audio, index) => (
                  <div key={`audio-${index}`} className="audio-item">
                    <p className="audio-title">{audio.title}</p>
                    <ReactAudioPlayer
                      class="audio-player"
                      src={audio.url}
                      controls
                      autoplay
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {images.length > 0 && (
            <>
              <div className="devider"></div>
              <div className="slider-container">
                <p className="title">جميع الصور:</p>
                <div
                  style={{
                    backgroundColor: "#fff",
                  }}
                  className="slider-wrapper"
                >
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={15}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    breakpoints={{
                      950: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      1184: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {images.map((img, index) => (
                      <SwiperSlide key={`image-${index}`}>
                        <div className="slide-card card-img">
                          <img src={img.url} />
                          <p>{img.title}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </>
          )}
          {videos.length > 0 && (
            <>
              <div className="devider"></div>
              <div className="slider-container">
                <p className="title">جميع الفيديوهات:</p>
                <div className="slider-wrapper">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={15}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    breakpoints={{
                      1184: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {videos.map((video, index) => (
                      <SwiperSlide key={`image-${index}`}>
                        <div className="slide-card card-vd">
                          <ReactPlayer
                            style={{
                              maxWidth: "100%",
                            }}
                            url={video.url}
                            controls
                          />
                          <p>{video.title}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </>
          )}
          <div className="box-link-channel" style={{display: "flex", flexDirection: "column", justifyContent: "center", margin: "65px 0"}}>
            <h2 className="text-center"> للاطلاع على المزيد من المقاطع والتوثيقات اضغط هنا ⬇️</h2>
            <a
              href="https://t.me/+JSlWHRl2cqBlYTM0"
              target="_blank"
              className="link-btn"
              style={{margin: '20px auto'}}
            >
              {"القناة"}
            </a>
          </div>
          {comments.length > 0 && (
            <>
              <div className="devider"></div>
              <div className="slider-container">
                <p className="title">كل التعليقات:</p>
                <div className="slider-wrapper">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    breakpoints={{
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {comments.map((cmt, index) => (
                      <SwiperSlide className="card" key={`image-${index}`}>
                        <p>{cmt.title}</p>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
