
import React, { useState,useEffect } from "react";
import { api } from "../../Utils/api";

import Home from './Home/Home';
import Chart from './chart/Chart';
import Footer from './footer/Footer';
import WidghtLg from './widghtLg/WidghtLg';

import TopBar from './Topbar/TopBar';
import Porcentage from './porcentage/Porcentage';
import WidghtSm from './widghtSm/WidghtSm';
// import ReplacePie from './replacePie/ReplacePie';


function Homepage() {
  const [generalStats, setGeneralStats] = useState([]);
  const [countTeachersByCountry, setCountTeachersByCountry] = useState([]);
  const [countAdvocateLang, setCountAdvocateLang] = useState({
    fr: 0,
    pt: 0,
  });
  const [countTeacherLang, setCountTeacherLang] = useState({
    fr: 0,
    pt: 0,
  });
  const [chartMonthData, setChartMonthData] = useState([
    { name: 'يناير', uv: 0, },
    { name: 'فبراير', uv: 0, },
    { name: 'مارس', uv: 0, },
    { name: 'إبريل', uv: 0, },
    { name: 'مايو', uv: 0, },
    { name: 'يونيو ', uv: 0, },
    { name: 'يوليو', uv: 0, },
    { name: 'أغسطس ', uv: 0, },
    { name: 'سبتمبر', uv: 0, },
    { name: 'أكتوبر', uv: 0, },
    { name: 'نوفمبر', uv: 0, },
    { name: 'ديسمبر', uv: 0, },
  ]);
  const [dataLastMonthStat, setDataLastMonthStat] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await api.get("user/homepage-stats");
      if (response.status == 200 && response.data) {
        setGeneralStats(response.data.generalStats);
        const transformed =[
          { name: 'يناير', uv: response.data?.NewMuslimUsersCountLastYearByMonth['1'] || 0, },
          { name: 'فبراير', uv: response.data?.NewMuslimUsersCountLastYearByMonth['2'] || 0, },
          { name: 'مارس', uv: response.data?.NewMuslimUsersCountLastYearByMonth['3'] || 0, },
          { name: 'إبريل', uv: response.data?.NewMuslimUsersCountLastYearByMonth['4'] || 0, },
          { name: 'مايو', uv: response.data?.NewMuslimUsersCountLastYearByMonth['5'] || 0, },
          { name: 'يونيو ', uv: response.data?.NewMuslimUsersCountLastYearByMonth['6'] || 0, },
          { name: 'يوليو', uv: response.data?.NewMuslimUsersCountLastYearByMonth['7'] || 0, },
          { name: 'أغسطس ', uv: response.data?.NewMuslimUsersCountLastYearByMonth['8'] || 0, },
          { name: 'سبتمبر', uv: response.data?.NewMuslimUsersCountLastYearByMonth['9'] || 0, },
          { name: 'أكتوبر', uv: response.data?.NewMuslimUsersCountLastYearByMonth['10'] || 0, },
          { name: 'نوفمبر', uv: response.data?.NewMuslimUsersCountLastYearByMonth['11'] || 0, },
          { name: 'ديسمبر', uv: response.data?.NewMuslimUsersCountLastYearByMonth['12'] || 0, },
        ]
        setChartMonthData(transformed);
        setCountAdvocateLang({
          fr: response.data?.countAdvocateByLanguage['fr'],
          pt: response.data?.countAdvocateByLanguage['pt'],
        });
        setCountTeacherLang({
          fr: response.data?.countTeachersByLanguage['fr'],
          pt: response.data?.countTeachersByLanguage['pt'],
        });
        setCountTeachersByCountry(response.data?.countTeachersByCountry);

        const sumNewMuslimUsersCount = calculateSum(response.data?.NewMuslimUsersCountByLastMonthAndTotalFound);
        const sumTeachersUsersCount = calculateSum(response.data?.TeachersUsersCountByLastMonthAndTotalFound);

        const totalLastMonthStat = {
          newMuslim: {
            allCounts: response.data?.generalStats?.Total_NewMuslim || 0,
            total: sumNewMuslimUsersCount?.fr + sumNewMuslimUsersCount?.pt,
            fr: sumNewMuslimUsersCount?.fr,
            pt: sumNewMuslimUsersCount?.pt
          },
          teacher: {
            allCounts: response.data?.generalStats?.Total_teachers || 0,
            total: sumTeachersUsersCount?.fr+sumTeachersUsersCount?.pt,
            fr: sumTeachersUsersCount?.fr,
            pt: sumTeachersUsersCount?.pt
          },
          khirrij: {
            allCounts: response.data?.countAllKhirrij || 0,
            total: (response.data?.countKhirrijByLanguage['fr'] || 0) + (response.data?.countKhirrijByLanguage['pt'] || 0),
            fr: response.data?.countKhirrijByLanguage['fr'] || 0,
            pt: response.data?.countKhirrijByLanguage['pt'] || 0,
          }
        }

        setDataLastMonthStat(totalLastMonthStat);
      }
    } catch (error) {
      console.log("Failed to fetch data: ", error);
    }
  }


  const calculateSum = (arr) => {
    let sumFr = 0;
    let sumPt = 0;
    arr.forEach(item => {
      sumFr += item.fr;
      sumPt += item.pt;
    });
    return {fr: sumFr,pt: sumPt}
  };
  
  return (
    <div className="App" style={{backgroundColor:'#fff'}}>
      <TopBar />

      <Home />

      <Chart
        generalStats={generalStats}
        chartMonthData={chartMonthData}
        countAdvocateLang={countAdvocateLang}
        countTeacherLang={countTeacherLang}
      />
      {/* <Porcentage /> */}
      <WidghtLg dataLastMonthStat={dataLastMonthStat} />

      <WidghtSm countTeachersByCountry={countTeachersByCountry} />
      {/* <ReplacePie /> */}

      <Footer />
    </div>
  );
}

export default Homepage;
