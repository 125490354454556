import React, { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import ImgLogo from "../../Layouts/images/global/logos.png";
import useAuth from "../../Utils/Hooks/useAuth";

import "./Account.scss";
import Steps from "./Steps";

const Register = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user) {
      navigate(-1, { replace: true });
    }
  }, [user, navigate]);
  const [step, setstep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    password: "",
    degree: "",
    language: [],
    role: "",
    phone_number: "",
    quizResult: [],
  });
  console.log(formData);
  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    console.log(input, e);
    if (e.target) {
      const { value } = e.target;

      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [input]: e,
      }));
    }
  };

  const handleInputArray = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: [value],
    }));
  };

  return (
    <div className="account">
      <Container>
        <div className="register-page">
          <div className="content-register">
            <h2 className="title-content">Sign up</h2>
            <div className="steps">
              <Steps
                formData={formData}
                handleInputArray={handleInputArray}
                handleInputData={handleInputData}
                nextStep={nextStep}
                prevStep={prevStep}
                step={step}
              />
            </div>
          </div>
          <div className="header-register">
            <img src={ImgLogo} alt="منصة بلاغ الدعوية" style={{width: '180px'}} />
            {/* <h1 className="title-account">منصة بلاغ</h1> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Register;
