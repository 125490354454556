import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Button } from "react-bootstrap";

const QuestionsItem = ({
  q,
  handlePrevQ,
  handleNextQ,
  show,
  setQuizResult,
  currentStep,
  length,
}) => {
  const [_ans, setAns] = useState("");
  const { question, quizType, answer } = q;
  const handleSubmit = (e) => {
    e.preventDefault();
    // setQuizResult((v) => [...v, { question, answer: _ans }]);

    handleNextQ();
  };
  useEffect(() => {
    setQuizResult((v) => {
      const qi = v.findIndex((el) => el.question === question);
      if (qi === -1) {
        return [...v, { question, answer: _ans }];
      } else {
        const oldv = v;
        oldv[qi].answer = _ans;
        return oldv;
      }
    });
  }, [_ans, question, setQuizResult]);
  return (
    <Form
      className="align-items-center"
      style={{ display: show ? "flex" : "none" }}
      onSubmit={handleSubmit}>
      <div className="action-btn">
        <Button
          variant="default"
          className="btn-back btn-step"
          style={{ width: 50 }}
          onClick={handlePrevQ}
          disabled={currentStep === 0}>
          <i className="fa fa-angle-left"></i>
        </Button>
      </div>
      <div
        className="questions-register info steps-register"
        style={{ width: 800 }}>
        <h3 className="title-form">forensic science</h3>
        <Form.Group className="mb-3">
          <Form.Label className="label-question">Q1 : {question} ?</Form.Label>
          {quizType === "one" ? (
            <Form.Control
              placeholder="answer with what you know!"
              aria-label="answer"
              aria-describedby="answer"
              name="answer"
              id="answer"
              value={_ans}
              onChange={(e) => setAns(e.target.value)}
              required
            />
          ) : (
            <>
              {answer.map((ans, i) => (
                <Form.Check
                  key={ans[`choice_${i}`]}
                  type="radio"
                  id={`choice_${i}`}
                  label={ans[`choice_${i}`]}
                  name={`choice_${i}`}
                  checked={_ans === ans[`choice_${i}`]}
                  value={ans[`choice_${i}`]}
                  onChange={(e) => setAns(e.target.value)}
                />
              ))}
            </>
          )}
        </Form.Group>
      </div>
      <div className="action-btn">
        <Button
          variant="default"
          type="submit"
          className="btn-next btn-step"
          style={{ width: 50 }}
          disabled={currentStep === length - 1}>
          <i className="fa fa-angle-right"></i>
        </Button>
      </div>
    </Form>
  );
};

export default QuestionsItem;
