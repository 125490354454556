import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { api } from "../../Utils/api";
import { useLang } from "../../Utils/Context/LangContext";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import useSearch from "../../Utils/Hooks/useSearch";
import { clean } from "../../Utils/transform";

import ConfirmationAddFav from "../translators/ConfirmationAddFav";
const ListDialogue = () => {
  const [show, setShow] = useState(false);
  const [q, setQ] = useState("");
  const { t, language } = useLang();
  const [dialogues, setdialogues] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/posts");
        setdialogues(data.posts.filter((elt) => elt.type === "dialogue"));
      } catch (e) {}
    })();
  }, []);
  const handleConfirm = () => {
    console.log("fav");
  };
  const handleClose = () => setShow(false),
    handleShow = () => setShow(true);
  const res = useSearch(q, dialogues);
  return (
    <>
      <div className="listDialogue">
        <Container>
          <div className="box-search">
            <h2 className="title-page">{t("Dialogue Methode")}</h2>
            <div
              className={`${
                language !== "ar" ? "search" : "searchv2"
              } d-flex justify-content-between align-items-center`}>
              <input
                className="input-search"
                placeholder={t("search")}
                type="text"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
          </div>
          <div className="list-box">
            <Row className="row-list-dialogue">
              {res.map((elt) => (
                <>
                  <Col className="column-dialogue col-lg-4" key={elt._id}>
                    <Card>
                      <Link state={elt} to={"/daaiya/dialogue/" + elt._id}>
                        {elt.video ? (
                          (elt.video + "").startsWith("https://www.youtube") ? (
                            <iframe
                              className="media-dialogue"
                              width="100%"
                              height="100%"
                              src={elt.video}
                              title={elt.title}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                          ) : (
                            <video
                              className="media-dialogue"
                              width="100%"
                              height="100%"
                              src={elt.video}
                              title={elt.title}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></video>
                          )
                        ) : (
                          <Card.Img
                            className="media-dialogue"
                            variant="top"
                            src={elt.photo}
                          />
                        )}
                      </Link>
                      <Card.Body>
                        <div className="content-body w-75">
                          <Link state={elt} to={"/daaiya/dialogue/" + elt._id}>
                            <Card.Title className="text-truncate">
                              {elt.title}
                            </Card.Title>
                          </Link>
                          <Card.Text
                            dangerouslySetInnerHTML={{
                              __html: clean(elt.body),
                            }}
                            className="text-truncate"></Card.Text>
                        </div>
                        <Button
                          variant="default"
                          onClick={handleShow}
                          className="w-25">
                          <i className="fa fa-heart-o"></i>
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ))}
            </Row>
          </div>
        </Container>
        <ConfirmationAddFav
          show={show}
          onHide={handleClose}
          handleConfirm={handleConfirm}
        />
      </div>
    </>
  );
};

export default ListDialogue;
