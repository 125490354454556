import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import useAuth from "../../Utils/Hooks/useAuth";
import { Container, Form, Spinner, Toast } from "react-bootstrap";
import ImgLogo from "../../Layouts/images/global/logos.png";

import "./Account.scss";
import { api } from "../../Utils/api";
import { roleMap } from "../../Utils/transform";

const Login = (props) => {
  const [email, setEmail] = useState(""),
    [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [emailError, setemailError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isloading, setisloading] = useState(false);
  const { updateAuth, user } = useAuth();
  const location = useLocation();
  useEffect(() => {
    if (user) {
      navigate(-1, { replace: true });
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError("");
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setemailError(
      !email
        ? "Email field is required"
        : !emailRegex.test(email)
        ? "Invalid email adress"
        : ""
    );
    setpasswordError(!password ? "Password field is required" : "");
    if (!email || !password || !emailRegex.test(email)) return;
    try {
      setisloading(true);
      const { data } = await api.post("/user/login", {
        email: email,
        password: password,
      });
      updateAuth(data.token, data.user, data.user.role);
      setisloading(false);

      const origin = location.state?.from?.pathname || "/";
      navigate(origin, { replace: true });
    } catch (e) {
      setisloading(false);
      setLoginError(e?.response?.data?.message);
    }
  };

  return (
    <div className="account">
      <Container>
        <div className="login-page">
          <div className="header-login">
            <img src={ImgLogo} alt="منصة بلاغ الدعوية" style={{width: '180px'}} />
            {/* <h2 className="title-account">منصة بلاغ</h2> */}
          </div>
          <form className="form-login">
            <h2 className="title-login">Login</h2>
            <div className="search box-mail my-4">
              <Form.Control
                className={"input-search" + (emailError ? " input-error" : "")}
                placeholder="Email"
                type="email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="span-error">{emailError}</span>
            </div>
            <div className="search box-pass">
              <Form.Control
                type="password"
                className={
                  "input-search" + (passwordError ? " input-error" : "")
                }
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="span-error">{passwordError}</span>
            </div>
            {!isloading ? (
              <div className="action-btn">
                <Link to="/register" className="link-register btn">
                  Sign up
                </Link>
                <button onClick={handleLogin} className="btn-enter">
                  Enter
                </button>
              </div>
            ) : (
              <div style={{ marginTop: 10 }} className="login-spinner">
                {[...Array(7).keys()].map((key) => (
                  <Spinner
                    size="sm"
                    key={key}
                    animation="grow"
                    style={{ color: "#00376f" }}
                  />
                ))}
              </div>
            )}
            <Link to="/forget-password" className="link-forget">
              Forget Password
            </Link>
          </form>
        </div>
        <Toast
          show={loginError !== ""}
          style={{
            position: "absolute",
            top: 0,
            color: "white",
            left: 5,
            fontWeight: "bold",
            fontSize: 17,
          }}
          className="d-inline-block m-1"
          bg={"danger"}>
          <Toast.Body className={"danger"}>{loginError}</Toast.Body>
        </Toast>
      </Container>
    </div>
  );
};

export default Login;
