import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Advocacy from "../../../Layouts/images/home/Advocacy.png";
import Identifier from "../../../Layouts/images/home/Identifier.png";
import nonMuslims from "../../../Layouts/images/home/non-Muslims.png";
import { api } from "../../../Utils/api";
import StatsCard from "./StatsCard";
import "./Home.scss";
import { useLang } from "../../../Utils/Context/LangContext";

export default function Home() {
  const [stats, setstats] = useState({});
  const { t } = useLang();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users/statistics");
        const newstats = {
          total: 0,
          countTranslator: 0,
          countIdentifier: 0,
          countAdvocate: 0,
          countNewMuslim: 0,
        };
        data.result.forEach((elt) => {
          console.log("elt: ", elt);
          if (elt._id !== "ADMIN") newstats.total += elt.count;
          if (elt._id === "ADVOCATE") newstats.countAdvocate = elt.count;
          if (elt._id === "TRANSLATOR") newstats.countTranslator = elt.count;
          if (elt._id === "IDENTIFIER") newstats.countIdentifier = elt.count;
          if (elt._id === "NEW_MUSLIM") newstats.countNewMuslim = elt.count;
        });
        setstats(newstats);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <div className="home-container">
      <Container>
        <div className="cards-container">
          <StatsCard
            link={`/supporter/report/advocate`}
            image={Advocacy}
            title={t("Report Advocate")}
            name="Advocacy"
            number={Object.keys(stats).length === 0 ? "0" : stats.countAdvocate}
          />
          <StatsCard
            link={`/supporter/report/teacher`}
            image={Identifier}
            title={t("Report Teacher")}
            name="Identifier"
            number={
              Object.keys(stats).length === 0 ? "0" : stats.countIdentifier
            }
          />
          <StatsCard
            link={`/supporter/new-muslims`}
            image={nonMuslims}
            title={t("New Muslims Data")}
            name="new-muslims"
            number={
              Object.keys(stats).length === 0 ? "0" : stats.countNewMuslim
            }
          />
          <StatsCard
            link={`/supporter/channel`}
            image={nonMuslims}
            title="قناة التوثيق"
            name="channel"
          />
        </div>
      </Container>
    </div>
  );
}
