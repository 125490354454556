import {useState} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import ImgUserFav from "../../Layouts/images/global/fav-user.png";
import ConfirmationAddFav from "./ConfirmationAddFav";

const ListTranslators = () => {
    const [show, setShow] = useState(false);

    const   handleClose = () => setShow(false),
            handleShow = () => setShow(true);
  return (
    <div className='listTranslators'>
        <Container>
        <div className='header-translate'>
                <h2 className='title-page'>Cooperating  translators</h2>
                <div className='search'>
                    <input className='input-search' placeholder='search' type='text' />
                </div>
            </div>
            <div className='listTranslate'>
                <Row className='row-translate'>
                    {[...Array(24)].map(() => (
                        <Col className="column-translate col-lg-6 col-md-6">
                            <div className='card-trans'>
                                <div className='details-user'>
                                    <img src={ImgUserFav} alt="" />
                                    <div className='info-user'>
                                        <h4 className='name-user'>Naim</h4>
                                        <p className='role-user'>Translator</p>
                                    </div>
                                </div>
                                <div className='action-translate'>
                                    <Button variant="default">
                                        Send a message 
                                        <i className='fa fa-send-o icon-button'></i>
                                    </Button>
                                    <Button variant="default" onClick={handleShow}>
                                        Add to favorite 
                                        <i className='fa fa-heart-o icon-button'></i>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
        <ConfirmationAddFav show={show} onHide={handleClose} />
    </div>
  )
}

export default ListTranslators