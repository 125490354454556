import { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useLang } from "../../Utils/Context/LangContext";

export default function SelectActivity({
  name,
  setSelectItem,
  setfValues,
  selectItem,
  number,
  title,
  value,
  type,
  options,
}) {
  const { t, language } = useLang();
  const handleChange = (e) => {
    const previousOption = selectItem;
    setSelectItem(e.target.value);

    // remove previous option from values object
    setfValues((prevValues) => {
      const newValues = { ...prevValues };
      delete newValues[previousOption];
      return newValues;
    });
  };

  useEffect(() => {
    setSelectItem(value);
  }, []);

  return (
    <div className="form-add-activity mx-4 my-4">
      <div className="header-form">
        <h4 className="title-add-link">
          {number}- {title}
        </h4>
      </div>
      <div className="box-input my-2">
        <InputGroup className="link-input" key={`input control ${name}`}>
          <Form.Control
            name={`${name}`}
            as={type}
            placeholder={t("Fill Info")}
            style={{ height: 70 }}
            defaultValue={value || ""}
            required
            onChange={handleChange}
          >
            {options.map((o, index) => (
              <option
                key={`select-option${index}+${o["title"][language]}`}
                value={o["name"]}
              >
                {o["title"][language]}
              </option>
            ))}
          </Form.Control>
        </InputGroup>
      </div>
    </div>
  );
}
