import React from "react";
import { useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router";
import AddInputActivity from "../../../Components/activity/AddInputActivity";
import Confirmation from "../../../Components/global/Confirmation";
import { api } from "../../../Utils/api";
import { useRoleFromLoc } from "../../../Utils/transform";
import { toast } from "react-toastify";

import "./Activity.scss";
import LIST_LINKS from "./LIST_LINKS";
import { useLang } from "../../../Utils/Context/LangContext";
import HttpHandlerHoc from "../../../Utils/HOCS/httpHandlerHoc";
import InputActivity from "../../../Components/activity/InputActivity";
import SelectActivity from "../../../Components/activity/SelectActivity";
export default function AddActivity() {
  const [modalShow, setModalShow] = useState(false);
  const [values, setfValues] = useState({});
  const [selectItem, setSelectItem] = useState(null);
  const location = useRoleFromLoc();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const exist = LIST_LINKS[ROLE].map((el) => {
      if (el.type === "select") {
        if (el.name == "ban_action") {
          const item = el.options.map((c) => {
            if (c.name == selectItem) {
              return values[c.name];
            }
          });
          return item;
        }
      } else {
        return values[el.name];
      }
    });
    if (
      exist.findIndex((el) => {
        if (Array.isArray(el)) {
          let res = el.find((l) => l !== "" && l !== undefined && l !== null);
          if (res) {
            return false;
          } else {
            return true;
          }
        } else {
          return el === "" || el === undefined;
        }
      }) !== -1
    ) {
      setModalShow(false);
      toast.error("fill the form first!");
      return;
    }
    const week = Object.entries(values).reduce((acc, [key, value]) => {
      acc.push({
        name: key,
        value,
      });
      return acc;
    }, []);

    const data = await api.post("activity", week);
    if (data.status === 201) {
      navigate("/" + location + "/activity");
      setModalShow(false);
    }
  };
  const setValues = (b) => {
    setfValues((v) => ({ ...v, ...b }));
  };
  const ROLE = useRoleFromLoc();
  const { t, language } = useLang();
  return (
    <>
      <div className="addActivity">
        <Container>
          <div
            className="w-100 d-flex align-items-center justify-content-between mb-5"
            style={{ height: 80 }}
          >
            <h5 className="p-0 m-0"></h5>
          </div>
          {LIST_LINKS[ROLE].map(({ title, type, name, ...rest }, index) => {
            if (type === "select") {
              const options = rest.options;
              return (
                <>
                  <SelectActivity
                    setSelectItem={setSelectItem}
                    selectItem={selectItem}
                    setfValues={setfValues}
                    key={`da3iya_${title[language]}_${index}`}
                    number={index + 1}
                    title={title[language]}
                    type={type}
                    name={name}
                    options={options}
                  />
                  {selectItem && (
                    <div className="form-add-activity mx-4 my-4">
                      <div className="header-form">
                        <h4 className="title-add-link">
                          {index + 1}-{" "}
                          {
                            options.find(
                              (option) => option.name === selectItem
                            )["title"][language]
                          }
                        </h4>
                      </div>
                      <div className="box-input my-2">
                        <InputGroup
                          className="link-input"
                          key={`input control ${selectItem}`}
                        >
                          <Form.Control
                            name={`${selectItem}`}
                            type={
                              options.find(
                                (option) => option.name === selectItem
                              ).type
                            }
                            placeholder={t("Fill Info")}
                            style={{ height: 70 }}
                            required
                            onChange={(e) =>
                              setValues({ [selectItem]: e.target.value })
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                </>
              );
            } else {
              return (
                <InputActivity
                  setValues={setValues}
                  key={`da3iya_${title[language]}_${index}`}
                  number={index + 1}
                  title={title[language]}
                  type={type}
                  name={name}
                />
              );
            }
          })}
          <div className="w-100 mb-5 mt-4 d-flex justify-content-center">
            <button
              className="confirmationButton"
              onClick={() => setModalShow(true)}
            >
              confirmation
            </button>
          </div>
        </Container>
        <Confirmation
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleConfirm={handleSubmit}
        />
      </div>
    </>
  );
}
