import React from 'react'
import imgDialoge from "../../../Layouts/images/dialogue.png";
import {Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const ItemCertificate = () => {
  return (
    <div className='column-dialogue col-lg-4 col-md-6'>
    <Card>
        <Link to="/teachers/certificate/1">
            <Card.Img className='media-dialogue' variant="top" src={imgDialoge} />
        </Link>
        <Card.Body>
            <div className='content-body'>
                <Link to="/daaiya/dialogue/1">
                    <Card.Title>Name New Muslim</Card.Title>
                </Link>
            </div>
            <Button variant="default"><i className='fa fa-upload'></i></Button>
        </Card.Body>
    </Card>
</div>
  )
}

export default ItemCertificate