import React from 'react'
import ListDialogue from '../../../Components/dialogue/listDialogue';
import "./Dialogue.scss";
const Dialogue = () => {
  return (
    <div className='dialogue'>
        <ListDialogue />
    </div>
  )
}

export default Dialogue