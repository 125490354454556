import { useEffect, useState } from "react";

const useSearch = (q, data) => {
  const [res, setRes] = useState([]);
  useEffect(() => {
    const res = data.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(q.toLowerCase());
    });
    setRes(res);
  }, [q, data]);
  return res;
};

export default useSearch;
