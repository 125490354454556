import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { Container, Button, Row, Col, Modal, Form } from "react-bootstrap";
import ImgUser from "../../../Layouts/images/user1.png";
import { transformDate } from "../../../Utils/transform";
import "./Report.scss";
import { api } from "../../../Utils/api";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const ReportsUser = (props) => {
  const location = useLocation();
  const [monthlyActivity, setMonthlyActivity] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const [mode, setMode] = useState("DAILY");

  const { user } = useParams();
  useEffect(() => {
    (async () => {
      const { data: months } = await api.get("activity/month", {
        params: {
          user,
        },
      });
      const { data: daily } = await api.get("activity", {
        params: {
          user,
        },
      });
      setMonthlyActivity(months);
      setDailyActivity(daily.weeks);
    })();
  }, [user]);
  return (
    <>
      <div className="reportsUser report">
        <div className="header-page header-report">
          <Container>
            <div className="content-header-page">
              <div className="info-user">
                <img src={ImgUser} alt="" width={100} />
                <div className="info">
                  <h4 className="name-user mx-2">{location.state.name}</h4>
                  <h4 className="mx-2" style={{ color: "gray" }}>
                    {location.state.role}
                  </h4>
                </div>
              </div>

              <Form.Select
                size="md"
                style={{ width: 200 }}
                onChange={(e) => setMode(e.target.value)}
              >
                <option>DAILY</option>
                <option>MONTHLY</option>
              </Form.Select>
            </div>
          </Container>
        </div>
        <div className="reportsUser-items reportItem">
          <Container>
            <div className="content-report">
              {mode === "DAILY" ? (
                <Row className="row-report">
                  {dailyActivity?.map((activity, index) => (
                    <Col className="column-report col-md-6 col-lg-6">
                      <div className="box-user">
                        <Link to={`${activity._id}`} className="details-user">
                          <div className="info-user">
                            <h4 className="name-user text-capitalize">
                              Day {index + 1}
                            </h4>
                            {/* <p className="role-user text-capitalize">
                              DATE:{" "}
                              {activity.date_creation
                                ? transformDate(activity.date_creation)
                                : transformDate(activity.createdAt)}
                            </p> */}
                          </div>
                        </Link>
                        <div className="action-report">
                          <Link
                            to={`${activity._id}`}
                            className="btn-action"
                            variant="default"
                          >
                            <i className="fa fa-eye icon-button"></i>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row className="row-report">
                  {monthlyActivity.map((activity, index) => (
                    <Col className="column-report col-md-6 col-lg-6">
                      <div className="box-user">
                        <Link
                          to={`${activity._id.year}/${activity._id.month}`}
                          className="details-user"
                        >
                          <div className="info-user">
                            <h4 className="name-user text-capitalize">
                              Mounth {index + 1}:{" "}
                              {MONTHS[activity._id.month - 1]}
                            </h4>
                            <p className="role-user text-capitalize">
                              work mounth: {activity.total}
                            </p>
                          </div>
                        </Link>
                        <div className="action-report">
                          <Link
                            to={`${activity._id.year}/${activity._id.month}`}
                            className="btn-action"
                            variant="default"
                          >
                            <i className="fa fa-eye icon-button"></i>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ReportsUser;
