import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../../../Utils/api";
import ModalConfirm from "./ModalConfirm";

const RoleUser = ({ nextStep, handleFormData, prevStep, values }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const submitFormData = async (e) => {
    e.preventDefault();
    if (values.role === "") {
      toast.error("choose role!");
    } else if (values.role === "SUPPORTER") {
      try {
        const data = await api.post("user/register", values);
        if (data.status === 201) {
          setShow(true);
        }
      } catch (error) {
        console.log(error);
        toast.error("something went wrong");
      }
    } else {
      nextStep();
    }
  };

  return (
    <div>
      <div className="roleUser steps-register info">
        <h3 className="title-form">You Want To Be</h3>
        <Form className="form-basic">
          <Row>
            <Col>
              <Form.Check
                type="radio"
                name="roleUser"
                id={`role-user-1`}
                label="an Advocate (داعية)"
                defaultValue="ADVOCATE"
                onChange={handleFormData("role")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                name="roleUser"
                id={`role-user-2`}
                label="an Identifier (معرف)"
                onChange={handleFormData("role")}
                defaultValue="IDENTIFIER"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                name="roleUser"
                id={`role-user-2`}
                label="a Teacher (معلم)"
                onChange={handleFormData("role")}
                defaultValue="TEACHER"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                name="roleUser"
                id={`role-user-2`}
                label="a Supporter (الداعمين)"
                onChange={handleFormData("role")}
                defaultValue="SUPPORTER"
              />
            </Col>
          </Row>
        </Form>
      </div>
      <div className="action-btn">
        <Button
          variant="primary"
          className="btn-back btn-step"
          onClick={prevStep}
          type="button"
        >
          <i className="fa fa-angle-left"></i> Back
        </Button>

        {values.role === "SUPPORTER" ? (
          <Button
            variant="primary"
            className="btn-next btn-step"
            onClick={submitFormData}
            type="submit"
            disabled={!values.role}
          >
            Confirm
          </Button>
        ) : (
          <Button
            onClick={submitFormData}
            variant="default"
            type="submit"
            className="btn-next btn-step"
          >
            Next <i className="fa fa-angle-right"></i>
          </Button>
        )}
      </div>
      <ModalConfirm dataForm={values} handleClose={handleClose} show={show} />
    </div>
  );
};

export default RoleUser;
