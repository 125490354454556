import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { api } from "../../Utils/api";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";

import ConfirmationPost from "./ConfirmationPost";

const AddPost = ({ show, icon, mediaType, handleCancelPost }) => {
  const [modalShow, setModalShow] = useState(false);
  const [file, setFile] = useState("");
  const [description, setdescription] = useState("");
  const [tweet, setTweet] = useState("");
  async function handleShowAddPost() {
    try {
      if ((mediaType === "photo" || mediaType === "video") && !file) return;
      if (mediaType === "tweet" && !tweet) return;
      const formData = new FormData();
      formData.append("title", mediaType !== "tweet" ? "post" : tweet);
      formData.append("body", description);
      formData.append("type", "post");
      formData.append("mediaType", mediaType);
      if (file && mediaType === "photo") formData.append("post_photo", file);
      if (file && mediaType === "video") formData.append("post_video", file);
      await api.post("posts", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (e) {}
    setModalShow(false);
  }
  const cancelPost = (e) => {
    setFile("");
    setdescription("");
    handleCancelPost();
  };

  const label = "Add " + mediaType;
  return (
    <>
      <div className="addPost" style={{ display: show ? "" : "none" }}>
        <Form className="form-post">
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Add Description"
            className="input-desc"
            value={description}
            onChange={(evt) => setdescription(evt.target.value)}
          />
          {mediaType !== "tweet" ? (
            <label className="box-file">
              <Form.Control
                type="file"
                id="input-file"
                accept={
                  mediaType === "photo"
                    ? ".png, .jpg, .jpeg"
                    : "video/mp4,video/x-m4v,video/*"
                }
                className="input-file"
                onChange={(evt) => setFile(evt.target.files[0])}
              />
              {file?.name || label}{" "}
              <i className={`fa fa-${icon} icon-file`}></i>
            </label>
          ) : (
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Add Tweet"
              className="input-desc"
              value={tweet}
              onChange={(evt) => setTweet(evt.target.value)}
            />
          )}
          <div className="action-btn">
            <Button
              varient="default"
              className="btn-cancel"
              onClick={cancelPost}
            >
              Cancel
            </Button>
            <Button
              varient="default"
              className="btn-post"
              onClick={() => setModalShow(true)}
            >
              Post
            </Button>
          </div>
        </Form>
        <ConfirmationPost
          handleShowAddPost={handleShowAddPost}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </>
  );
};

export default AddPost;
