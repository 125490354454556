import React, { useEffect, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { api } from "../../../Utils/api";
import ItemStudent from "./ItemStudent";
import { toast } from "react-toastify";
import { useLang } from "../../../Utils/Context/LangContext";
import axios from "axios";

const Students = () => {
  const { t } = useLang();
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isRequesting, setRequesting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);


  async function getData() {
    setLoading(false);
    setRequesting(true);
    // Create a new cancel token for the API request
    const source = axios.CancelToken.source();
    try {
      const response = await api.get("user/get-blocked-new-muslim", {
        params: {
          search,
        },
        cancelToken: source.token, // Assign the cancel token to the request
      });
      if (response.status === 200) {
        setStudents(response.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // Ignore canceled requests
        console.log("Request canceled", error.message);
      } else {
        toast.error("Failed to fetch students");
      }
    } finally {
      setLoading(false);
      setRequesting(false);
    }
  }

  useEffect(() => {
    getData();
    return () => {
      // Cancel the API request when the component unmounts
      if (cancelToken) {
        cancelToken.cancel("Request canceled");
      }
    };
  }, [currentPage, search]);


  return (
    <>
      <div className="students dialogue content-grid">
        <div className="listStudent listDialogue">
          <Container>
            <div className="box-search">
              <h2 className="title-page">{t("Students")}</h2>
              <Form.Control
                type="text"
                placeholder={t("search")}
                className="input-styled "
                style={{maxWidth:"350px",marginBottom:"20px"}}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="list-box">
              <Row className="">
                <ItemStudent students={students} getData={getData} />
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Students;
