import React, { useState } from "react";

import ModalEditNewMuslim from "./ModalEditNewMuslim";

const ButtonEditNewMuslim = (user) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button
        onClick={handleShow}
        style={{
          background: "green",
          color: "white",
          height: 22,
          width: 80,
          borderRadius: 10,
          border: "none",
          fontSize: 14,
          marginBottom: "10px",
        }}
      >
        update
      </button>
      <ModalEditNewMuslim
        data={user.user}
        handleClose={handleClose}
        show={show}
      />
    </>
  );
};

export default ButtonEditNewMuslim;
