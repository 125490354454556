import React from "react";
import { Container, Button, Row } from "react-bootstrap";
import ImgIntro from "../../Layouts/images/global/img-intro.png";
import ImgIdea from "../../Layouts/images/global/idea-about.png";

import "./About.scss";
import { useLang } from "../../Utils/Context/LangContext";

const About = () => {
  const { t, language } = useLang();
  console.log(language === "ar");
  const icon =
    language === "ar" ? "fa fa-arrow-left mx-4" : "fa fa-arrow-right mx-4";
  console.log(icon);
  return (
    <div className="about">
      <div className="about-us">
        <Container>
          <h1 className="title-about">{t("About us")}</h1>
          <p className="desc-aboutus">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus
            facilis veritatis corporis autem ab aspernatur officia maxime, et
            porro, atque distinctio neque! Saepe harum excepturi architecto
            quidem rerum, perferendis accusamus.
          </p>
          <Button variant="default" className="btn-about">
            {t("Read More")}
            <i className={icon}></i>
          </Button>
        </Container>
      </div>
      <div className="introduction">
        <Container>
          <Row className="row-aboutus">
            <div className="column-img col-lg-5 col-md-12">
              <img src={ImgIntro} alt="" className="img-about" />
            </div>
            <div className="column-content col-lg-7 col-md-12">
              <h2 className="title-page">
                {t("Introduction to the platform")}
              </h2>
              <p className="desc-aboutus">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium nisi id dolorum pariatur asperiores quis magni
                repellendus, nam minus veritatis excepturi impedit, possimus
                accusamus dolores temporibus ab repellat, dolorem facere?
              </p>
              <Button variant="default" className="btn-about">
                {t("Read More")}
                <i className={icon}></i>
              </Button>
            </div>
          </Row>
        </Container>
      </div>
      <div className="explain">
        <Container>
          <Row className="row-aboutus">
            <div className="column-content col-lg-7 col-md-12">
              <h2 className="title-page">{t("explain an idea")}</h2>
              <p className="desc-aboutus">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium nisi id dolorum pariatur asperiores quis magni
                repellendus, nam minus veritatis excepturi impedit, possimus
                accusamus dolores temporibus ab repellat, dolorem facere?
              </p>
              <Button variant="default" className="btn-about">
                {t("Read More")}
                <i className={icon}></i>
              </Button>
            </div>
            <div className="column-img col-lg-5 col-md-12">
              <img src={ImgIdea} alt="" className="img-about" />
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;
