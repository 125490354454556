import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { api } from "../../../Utils/api";
import ModalConfirm from "./ModalConfirm";
import { toast } from "react-toastify";
import QuestionsItem from "./questionsItem";

const Quiz = ({ prevStep, values }) => {
  const [show, setShow] = useState(false);
  const [quizes, setQuizes] = useState([]);
  const [quizResult, setQuizResult] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const handleClose = () => setShow(false);
  const submitFormData = async (e) => {
    e.preventDefault();
    try {
      const data = await api.post("user/register", {
        ...values,
        quizResult,
      });
      if (data.status === 201) {
        setShow(true);
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };
  const lang = values?.language[0];
  useEffect(() => {
    (async () => {
      const { data } = await api.get("quizes", { params: { lang } });
      setQuizes(data.quizes);
    })();
  }, [lang]);
  const handleNextQ = () => {
    if (currentStep < quizes.length - 1) {
      setCurrentStep((v) => v + 1);
    }
  };
  const handlePrevQ = () => {
    if (currentStep > 0) {
      setCurrentStep((v) => v - 1);
    }
  };
  return (
    <div className="w-100">
      {quizes.map((quiz, i) => (
        <QuestionsItem
          q={quiz}
          key={quiz._id}
          show={currentStep === i}
          currentStep={currentStep}
          length={quizes.length}
          handleNextQ={handleNextQ}
          handlePrevQ={handlePrevQ}
          setQuizResult={setQuizResult}
        />
      ))}
      <div className="action-btn">
        <Button
          variant="primary"
          className="btn-back btn-step"
          onClick={prevStep}
          type="button"
        >
          <i className="fa fa-angle-left"></i> Back
        </Button>
        <Button
          variant="primary"
          className="btn-next btn-step"
          onClick={submitFormData}
          type="submit"
          disabled={currentStep !== quizes.length - 1}
        >
          Confirm
        </Button>
      </div>
      <ModalConfirm dataForm={values} handleClose={handleClose} show={show} />
    </div>
  );
};

export default Quiz;
