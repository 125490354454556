import React, { useState } from "react";
import { Container, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ImgLogo from "../../Layouts/images/global/logo.png";
import { api } from "../../Utils/api";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [rpassword, setRpassword] = useState("");
  const [isloading, setisloading] = useState(false);
  const navigate = useNavigate();
  const { token, id } = useParams();
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!password || !rpassword) {
      return toast.warn("fill the blanks");
    }
    if (password !== rpassword) {
      return toast.warn("passwords don't match");
    }
    try {
      setisloading(true);
      await api.post("/user/reset-password", {
        password,
        token,
        id,
      });
      toast.success("success");
      navigate("/login");
      setisloading(false);
    } catch (e) {
      setisloading(false);
      toast.error(e?.response.data);
    }
  };
  return (
    <div className="account">
      <Container>
        <div className="login-page">
          <div className="header-login">
            <img src={ImgLogo} alt="منصة بلاغ الدعوية" />
            <h2 className="title-account">منصة بلاغ</h2>
          </div>
          <form className="form-login">
            <h2 className="title-login">Reset Password</h2>
            <div className="search box-mail">
              <Form.Control
                className={"input-search"}
                placeholder="Password"
                type="password"
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete={false}
              />
            </div>
            <div className="search box-mail">
              <Form.Control
                className={"input-search"}
                placeholder="Repeat Password"
                type="password"
                defaultValue={rpassword}
                onChange={(e) => setRpassword(e.target.value)}
                autoComplete={false}
              />
            </div>

            {!isloading ? (
              <div className="action-btn">
                <Link to="/login" className="link-register btn">
                  login
                </Link>
                <button onClick={handleLogin} className="btn-enter">
                  Enter
                </button>
              </div>
            ) : (
              <div style={{ marginTop: 10 }} className="login-spinner">
                {[...Array(7).keys()].map((key) => (
                  <Spinner
                    size="sm"
                    key={key}
                    animation="grow"
                    style={{ color: "#00376f" }}
                  />
                ))}
              </div>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
