import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { api } from "../../../Utils/api";
import { toast } from "react-toastify";

import "./FilterMaterial.scss";
import { useLang } from "../../../Utils/Context/LangContext";

const FilterMaterial = () => {
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(`posts`, {
        title,
        body,
        type: "filter",
      });
      toast.success("we send your question!");
      setBody("");
      setTitle("");
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };
  const { t } = useLang();
  return (
    <div className="filterMaterial">
      <Container>
        <Form action="" className="form-filter" onSubmit={handleSubmit}>
          <div className="header-form">
            <h2 className="title-page">{t("Filter material")}</h2>
            <Button variant="primary" type="submit" className="btn-save">
              {t("Save")}
            </Button>
          </div>
          <div className="content-form">
            <Form.Group className="mb-3" controlId="inputTitle">
              <Form.Control
                type="text"
                placeholder={t("Title")}
                className="input-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="textParag">
              <Form.Control
                as="textarea"
                rows={10}
                className="textarea-parag"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </Form.Group>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default FilterMaterial;
