export const ADMIN = [5150];
export const EDITEUR = [1984];
export const USER = [2001];
export const TITLE_ROUTE_DAAIYA = "daaiya";
export const TITLE_ROUTE_MOARIF = "moarif";
export const TITLE_ROUTE_NEW_MUSLIM = "muslim";
export const TITLE_ROUTE_TEACHER = "teacher";
export const TITLE_ROUTE_SUPPORTER = "supporter";
export const ADVOCATE = ["ADVOCATE", "ADMIN"];
export const IDENTIFIER = ["IDENTIFIER", "ADMIN"];
export const USERR = ["USER", "ADMIN"];
export const NEW_MUSLIM = ["NEW_MUSLIM", "ADMIN"];
export const TEACHER = ["TEACHER", "ADMIN"];
export const SUPPORTER = ["SUPPORTER", "ADMIN"];
export const ALL = [
  "TEACHER",
  "ADMIN",
  "NEW_MUSLIM",
  "IDENTIFIER",
  "ADVOCATE",
  "SUPPORTER",
];
export const MONTHS = {
  ar: [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ],
  fr: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  pt: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};
