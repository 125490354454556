import React, { memo, useState } from "react";
import { Container, Button } from "react-bootstrap";
import iconFavorit from "../../Layouts/images/header/favorit.png";
import iconBell from "../../Layouts/images/header/Bell.png";
import { Link } from "react-router-dom";
import Notification from "./Notification";

const Header = () => {
  const [show, setShow] = useState(false);
  return (
    <header className="topbarHeader">
      <Container>
        <ul className="list-icons-topbar">
          <li className="list-notification">
            <Button variant="default" onClick={() => setShow(!show)}>
              <img src={iconBell} alt="" />
            </Button>
            <Notification show={show} />
          </li>
          <li>
            <Link to={"/favorites"}>
              <img src={iconFavorit} alt="" />
            </Link>
          </li>
        </ul>
      </Container>
    </header>
  );
};

export default memo(Header);
