import axios from "axios";
const baseURL = process.env.REACT_APP_BACKEND_LINK;
// const baseURL = "http://localhost:3030/api";

export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("token");
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  },
  (err) => {
    console.log(err);
  }
);
api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log(err);
    handlePermissions(err);
  }
);
export const handlePermissions = (e) => {
  if ([401, 403].indexOf(e.response.status) !== -1) {
    //remove token and user
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
  }
};
