import React from 'react';
import FilterPosts from "../../../Components/introductory/filterPosts";

import "./Introductory.scss";

export default function Introductory() {
  return (
    <div className='introductory-page'>
        <FilterPosts />
    </div>
  )
}
