import React from 'react'
import {Container} from "react-bootstrap";

import MapCenters from "./MapCenters";

import './index.scss';

const CenterIslamic = () => {
  return (
    <div className='centerIslamic'>
        <div className="header-page header-center">
            <Container>
                <div className="content-header-page">
                    <h2 className='title-page'>Islamic Centers</h2>
                    <div className='search'>
                        <input className='input-search' placeholder='search' type='text' />
                    </div>
                </div>
            </Container>
      </div>
      <MapCenters />
    </div>
  )
}

export default CenterIslamic