import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import IconEdit from "../../Layouts/images/global/edit.png";
import IconDelete from "../../Layouts/images/global/delete.png";
import { useEffect } from "react";
import { api } from "../../Utils/api";
import { useLocation, useNavigate } from "react-router";
import BoxAddActivity from "./boxAddActivity";
import { transformDate } from "../../Utils/transform";
import { memo } from "react";
import { useLang } from "../../Utils/Context/LangContext";

const ListActivity = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setisLoading] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    (async () => {
      const { data } = await api.get("activity");
      setActivities(data.weeks);
    })();
  }, [pathname, loading]);
  const handleDelete = async (id) => {
    await api.delete(`activity/${id}`);
    setisLoading((v) => !v);
  };
  const { t, language } = useLang();
  return (
    <>
      <BoxAddActivity />
      <div className="listActivity">
        <Container>
          <Row>
            {activities?.map((activity, _i) => (
              <Col md={6} sm={12} key={activity._id}>
                <div className="box-activity">
                  <div className="content-activity">
                    <h2 className="title-activity">
                      {t("Day")} {_i + 1}
                    </h2>
                    {/* <p className="date-activity">
                      {transformDate(activity.createdAt)}
                    </p> */}
                  </div>
                  <div
                    className={
                      language === "ar"
                        ? "action-activity-ar"
                        : "action-activity"
                    }
                  >
                    <Button
                      variant="default"
                      className="btn-action"
                      onClick={() =>
                        navigate(`edit/${activity._id}`, { state: activity })
                      }
                    >
                      <img src={IconEdit} alt="" className="icon-edit" />
                    </Button>
                    <Button
                      variant="default"
                      className="btn-action"
                      onClick={() => handleDelete(activity._id)}
                    >
                      <img src={IconDelete} alt="" className="icon-delete" />
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default memo(ListActivity);
