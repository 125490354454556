import React from "react";
import BoxAdd from "../../Components/global/BoxAdd";
import InfoProfile from "../../Components/global/InfoProfile";
import RecentPost from "../../Components/global/RecentPost";
export default function Profile() {
  return (
    <div className="profile">
      <InfoProfile />
      <BoxAdd />
      <RecentPost />
    </div>
  );
}
