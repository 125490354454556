import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import useAuth from "../Hooks/useAuth";
import ar from "../strings/ar.json";
import fr from "../strings/fr.json";
import en from "../strings/en.json";
import es from "../strings/es.json";
import pt from "../strings/pt.json";
const strings = {
  ar,
  fr,
  en,
  es,
  pt,
};

const getLang = (lang, role) => {
  if (!lang || !role) return;
  if (role === "IDENTIFIER") {
    return "ar";
  } else {
    return lang;
  }
};
const LangContext = createContext(null);
const LangProvider = ({ children }) => {
  const { user } = useAuth();
  const [language, setLanguage] = useState("ar");
  useEffect(() => {
    if (!user) {
      return;
    }
    const { role, language: userLang } = user;
    setLanguage(getLang(userLang, role));
    document.documentElement.lang = language;
    const isArabic = language === "ar";
    document.documentElement.dir = !isArabic ? "ltr" : "rtl";
    const body = document.querySelector("body");
    body.style["text-align"] = !isArabic ? "left" : "right";
  }, [language, user]);
  const t = (key) => {
    return strings[language] ? strings[language][key] || key : "error";
  };
  const value = {
    language,
    t,
  };
  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};
export default LangProvider;
export const useLang = () => {
  const lang = useContext(LangContext);
  return lang;
};
