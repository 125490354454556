import {memo} from 'react'
import { Modal, Button } from 'react-bootstrap'
import imgConfirmationFav from "../../Layouts/images/global/conf-fav.png";

const Confirmation = (props) => {
  return (
    <div className='confirmation'>
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='modal-confirmation'
        >
            <Modal.Body>
                <h4 className='title-confirmation'>Confirmation</h4>
                <img src={imgConfirmationFav} alt="" className='img-confirmation' />
                <p className='desc-confirmation'>
                    You want to delete this user from your favorite liste ?
                </p>
                <div className="buttons-confirmation">
                    <Button variant='default' className='btn-cancel' onClick={props.onHide}>Cancel</Button>
                    <Button variant='white' className='btn-success'>Ok</Button>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default memo(Confirmation);