import { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { api } from "../../Utils/api";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import useAuth from "../../Utils/Hooks/useAuth";
import { youtubeLinkToEmbed } from "../../Utils/transform";
import Lesson from "./Lesson";

const Lessons = () => {
  const [lessons, setLessons] = useState([]);

  const [currentV, setCurrentV] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { user, subTheme } = useAuth();
  const { language } = user;
  const dir = language === "ar" ? "rtl" : "ltr";
  const { category, id } = useParams();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`courses/${category}/${id}`);
      setLessons(data.lessons);
    })();
  }, [id, category]);
  console.log(lessons);
  //1 2 3
  const lesson0 = lessons[currentV] || false;

  return (
    <>
      <Row className="p-4 justify-content-between " style={{ direction: dir }}>
        {lesson0 && (
          <div
            style={{ width: "65%", backgroundColor: subTheme }}
            className="p-2 rounded">
            <Row className="video-container">
              <iframe
                className="media-dialogue"
                width="100%"
                height="100%"
                src={youtubeLinkToEmbed(lesson0.video)}
                title={lesson0.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </Row>
            <div className="mt-2 " style={{ color: "white" }}>
              <h1 className="text-truncate mb-3">{lesson0.title}</h1>
              <p>{lesson0.description}</p>
            </div>
            <div className="mt-2">
              {currentV === lessons?.length - 1 ? (
                <Button variant="success">Finish</Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => setCurrentV((v) => v + 1)}
                  disabled={currentV === lessons?.length - 1}>
                  Next
                </Button>
              )}

              <Button
                variant="secondary me-4"
                onClick={() => setCurrentV((v) => v - 1)}
                disabled={currentV === 0}>
                Prev
              </Button>
            </div>
          </div>
        )}

        <div style={{ width: "30%" }}>
          {lessons?.map((lesson, _i) => (
            <Lesson
              index={_i}
              setCurrentV={setCurrentV}
              lesson={lesson}
              key={lesson._id}
              dir={dir}
              currentLesson={lesson0._id}
            />
          ))}
        </div>
      </Row>
    </>
  );
};

export default Lessons;
