import { Form, InputGroup } from "react-bootstrap";
import { useLang } from "../../Utils/Context/LangContext";

export default function InputActivity({
  name,
  setValues,
  number,
  title,
  value,
  type,
}) {
  const { t } = useLang();
  return (
    <div className="form-add-activity mx-4 my-4">
      <div className="header-form">
        <h4 className="title-add-link">
          {number}- {title}
        </h4>
      </div>
      <div className="box-input my-2">
        <InputGroup className="link-input" key={`input control ${name}`}>
          <Form.Control
            name={`${name}`}
            type={type}
            placeholder={t("Fill Info")}
            style={{ height: 70 }}
            defaultValue={value || ""}
            required
            onChange={(e) => setValues({ [name]: e.target.value })}
          />
        </InputGroup>
      </div>
    </div>
  );
}
