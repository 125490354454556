import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Import Components Global
import Layout from "./Components/global/Layouts";
import About from "./Views/About/About";
import Profile from "./Views/Profile/Profile";
import Login from "./Views/Account/Login";
import Register from "./Views/Account/Register";
import RecentComment from "./Components/Comments/RecentComment";
import Setting from "./Components/global/Settings";
import Quations from "./Components/global/Quations";

// Import Pages Views from Dashboard leader
import Home from "./Views/Dashboard_Mo3arif/Home/Home";
import Introductory from "./Views/Dashboard_Mo3arif/Introductory/Introductory";

// Import Pages Views from Dashboard Advocate
import HomeAdvocate from "./Views/Dashboard_Da3iya/Home/Home";
import Dialogue from "./Views/Dashboard_Da3iya/Dialogue/Dialogue";
import DialogueDetails from "./Components/dialogue/DialogueDetails";
import ActivityAdvocate from "./Views/Dashboard_Da3iya/Activity/Activity";
import AddActivityAdvocate from "./Views/Dashboard_Da3iya/Activity/AddActivity";
import FavoriteTranslatorsAdvocate from "./Views/Dashboard_Da3iya/Favorite/FavoriteTranslators";
import TranslatorsAdvocate from "./Views/Dashboard_Da3iya/Translators/Translators";
import AdvocacyAdvocate from "./Views/Dashboard_Da3iya/Advocacy/Advocacy";
import FilterMaterial from "./Views/Dashboard_Da3iya/FilterMaterial/FilterMaterial";
import ComSpaceAdvocate from "./Views/Dashboard_Da3iya/ComSpace/ComSpace";
import RespondingAdvocate from "./Views/Dashboard_Da3iya/Responding/Responding";
import RespondingDetailsAdvocate from "./Views/Dashboard_Da3iya/Responding/RespondingDetails";

// Import Pages Views from Dashboard New Muslim
import HomeMuslim from "./Views/Dashboard_NewMuslim/Home/Home";
import CenterIslamic from "./Views/Dashboard_NewMuslim/CenterIslamic";
import ChooseTeam from "./Views/Dashboard_NewMuslim/ChooseTeam";

// Import Pages Views from Dashboard Teachers
import Certificate from "./Views/Dashboard_Teacher/Certificate/Certificate";
import Students from "./Views/Dashboard_Teacher/Students/Students";
import GraduatedStudents from "./Views/Dashboard_Teacher/GraduatedStudents/index";

// Import Pages Views from Dashboard leader
import HomeSupporter from "./Views/Dashboard_Supporter/Home/Home";
import Report from "./Views/Dashboard_Supporter/Report";
import ReportsUser from "./Views/Dashboard_Supporter/Report/ReportsUser";
import TasksUser from "./Views/Dashboard_Supporter/Report/TasksUser";
import MonthlyTasks from "./Views/Dashboard_Supporter/Report/MonthlyTasks";
import NewMuslims from "./Views/Dashboard_Supporter/NewMuslims/NewMuslims";
import Channel from "./Views/Dashboard_Supporter/Channel/Channel";
import KhirrijStudents from "./Views/Dashboard_Supporter/khirrij/KhirrijStudents";
import StudentsBlocked from "./Views/Dashboard_Supporter/StudentsBlocked/Students";

import {
  ADVOCATE,
  IDENTIFIER,
  TITLE_ROUTE_DAAIYA,
  TITLE_ROUTE_MOARIF,
  TITLE_ROUTE_NEW_MUSLIM,
  TITLE_ROUTE_TEACHER,
  TITLE_ROUTE_SUPPORTER,
  SUPPORTER,
  TEACHER,
  NEW_MUSLIM,
  ALL,
} from "./Utils/Constants";

import "./Layouts/styles/app.scss";
import RequireAuth from "./Routes/RequireAuth";
import ListActivity from "./Components/activity/listActivity";
import EditActivity from "./Views/Dashboard_Da3iya/Activity/edit";
import Courses from "./Components/Courses/Courses";
import Lessons from "./Components/Courses/Lessons";
import ComSpace from "./Views/Dashboard_Mo3arif/ComSpace/ComSpace";
import Favorites from "./Views/Dashboard_Da3iya/ComSpace/Favorites";
import Homepage from "./Views/homepage";
import ResetPassword from "./Views/RestPass";
import ForgetPassword from "./Views/ForgetPass";
import Inquiry from "./Views/Dashboard_Da3iya/Inquiry";
import MonthAct from "./Views/Dashboard_Da3iya/Activity/MonthAct";
import ListMonths from "./Components/activity/ListMonths";
import Categories from "./Components/Courses/Categories";
import AddLesson from "./Components/Courses/addLesson";
import NewMuslim from "./Views/Dashboard_Da3iya/NewMuslim";

function App() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forget-password" element={<ForgetPassword />} />
      <Route path="password-reset/:token/:id" element={<ResetPassword />} />
      <Route path="/homepage" element={<Homepage />} />
      {/* These Protect routers */}
      <Route element={<RequireAuth allowedRoles={ALL} />}>
        <Route path="/profile" element={<Layout />}>
          <Route index element={<Profile />}></Route>
          <Route path="comment/:postId" element={<RecentComment />} />
        </Route>
      </Route>
      <Route element={<RequireAuth allowedRoles={ALL} />}>
        <Route path="/favorites" element={<Layout />}>
          <Route index element={<Favorites />}></Route>
        </Route>
      </Route>
      <Route element={<RequireAuth allowedRoles={IDENTIFIER} />}>
        <Route path="/" element={<Layout />}>
          <Route
            exact
            path={"/"}
            element={<Navigate replace to={TITLE_ROUTE_MOARIF + "/"} />}
          ></Route>

          <Route exact path={TITLE_ROUTE_MOARIF} element={<Home />}></Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/introductory"}
            element={<Introductory />}
          ></Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/comspace"}
            element={<ComSpace />}
          ></Route>

          <Route
            path={TITLE_ROUTE_MOARIF + "/monthly-act"}
            element={<ListMonths />}
          />
          <Route
            path={TITLE_ROUTE_MOARIF + "/monthly-act/:year/:month"}
            element={<MonthAct />}
          />
          <Route
            path={TITLE_ROUTE_MOARIF + "/activity"}
            element={<ActivityAdvocate />}
          >
            <Route path="" element={<ListActivity />} />
            <Route path="add" element={<AddActivityAdvocate />} />
            <Route path="edit/:id" element={<EditActivity />} />
          </Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/advocacy"}
            element={<AdvocacyAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/about"}
            element={<About />}
          ></Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/recent-comment"}
            element={<RecentComment />}
          ></Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/setting"}
            element={<Setting />}
          ></Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/questions"}
            element={<Quations />}
          ></Route>
          <Route
            path={TITLE_ROUTE_MOARIF + "/suspicions/:id"}
            element={<RespondingDetailsAdvocate />}
          ></Route>
        </Route>
      </Route>
      <Route element={<RequireAuth allowedRoles={ADVOCATE} />}>
        <Route path="/" element={<Layout />}>
          <Route
            exact
            path={"/"}
            element={<Navigate replace to={TITLE_ROUTE_DAAIYA + "/"} />}
          ></Route>
          <Route
            exact
            path={TITLE_ROUTE_DAAIYA + "/"}
            element={<HomeAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/comment/:postId"}
            element={<RecentComment />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/introductory"}
            element={<Introductory />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/monthly-act"}
            element={<ListMonths />}
          />
          <Route
            path={TITLE_ROUTE_DAAIYA + "/monthly-act/:year/:month"}
            element={<MonthAct />}
          />
          <Route
            path={TITLE_ROUTE_DAAIYA + "/activity"}
            element={<ActivityAdvocate />}
          >
            <Route path="" element={<ListActivity />} />
            <Route path="add" element={<AddActivityAdvocate />} />
            <Route path="edit/:id" element={<EditActivity />} />
          </Route>
          <Route
            exact
            path={TITLE_ROUTE_DAAIYA + "/dialogue"}
            element={<Dialogue />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/dialogue/:id"}
            element={<DialogueDetails />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/favorites/translators"}
            element={<FavoriteTranslatorsAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/translators"}
            element={<TranslatorsAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/advocacy"}
            element={<AdvocacyAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/filter"}
            element={<FilterMaterial />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/inquiry"}
            element={<Inquiry />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/comspace"}
            element={<ComSpaceAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/new-muslim"}
            element={<NewMuslim />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/suspicions"}
            element={<RespondingAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/suspicions/:id"}
            element={<RespondingDetailsAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/about"}
            element={<About />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/setting"}
            element={<Setting />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/questions"}
            element={<Quations />}
          ></Route>
          <Route
            path={TITLE_ROUTE_DAAIYA + "/recent-comment"}
            element={<RecentComment />}
          ></Route>
        </Route>
      </Route>
      <Route element={<RequireAuth allowedRoles={NEW_MUSLIM} />}>
        <Route path="/" element={<Layout />}>
          <Route
            exact
            path={"/"}
            element={<Navigate replace to={TITLE_ROUTE_NEW_MUSLIM + "/"} />}
          ></Route>
          <Route
            exact
            path={TITLE_ROUTE_NEW_MUSLIM + "/"}
            element={<HomeMuslim />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/centers"}
            element={<CenterIslamic />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/teams"}
            element={<ChooseTeam />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/setting"}
            element={<Setting />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/favorites/translators"}
            element={<FavoriteTranslatorsAdvocate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/about"}
            element={<About />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/questions"}
            element={<Quations />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/recent-comment"}
            element={<RecentComment />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/courses"}
            element={<Categories />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/courses/:category"}
            element={<Courses />}
          ></Route>
          <Route
            path={TITLE_ROUTE_NEW_MUSLIM + "/courses/:category/:id"}
            element={<Lessons />}
          ></Route>
        </Route>
      </Route>
      <Route element={<RequireAuth allowedRoles={TEACHER} />}>
        <Route path="/" element={<Layout />}>
          <Route
            exact
            path={"/"}
            element={<Navigate replace to={TITLE_ROUTE_TEACHER + "/"} />}
          ></Route>
          <Route
            exact
            path={TITLE_ROUTE_TEACHER + "/"}
            element={<Home />}
          ></Route>

          <Route
            path={TITLE_ROUTE_TEACHER + "/monthly-act"}
            element={<ListMonths />}
          />
          <Route
            path={TITLE_ROUTE_TEACHER + "/monthly-act/:year/:month"}
            element={<MonthAct />}
          />
          <Route
            path={TITLE_ROUTE_TEACHER + "/activity"}
            element={<ActivityAdvocate />}
          >
            <Route path="" element={<ListActivity />} />
            <Route path="add" element={<AddActivityAdvocate />} />
            <Route path="edit/:id" element={<EditActivity />} />
          </Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/courses"}
            element={<Categories />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/courses/:category"}
            element={<Courses />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/courses/:category/:id"}
            element={<AddLesson />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/certificate"}
            element={<Certificate />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/students"}
            element={<Students />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/graduated-students"}
            element={<GraduatedStudents />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/about"}
            element={<About />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/setting"}
            element={<Setting />}
          ></Route>
          <Route
            path={TITLE_ROUTE_TEACHER + "/questions"}
            element={<Quations />}
          ></Route>
        </Route>
      </Route>
      <Route element={<RequireAuth allowedRoles={SUPPORTER} />}>
        <Route path="/" element={<Layout />}>
          <Route
            exact
            path={"/"}
            element={<Navigate replace to={TITLE_ROUTE_SUPPORTER + "/"} />}
          ></Route>
          <Route
            exact
            path={TITLE_ROUTE_SUPPORTER + "/"}
            element={<HomeSupporter />}
          ></Route>
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/report/:role"}
            element={<Report />}
          />
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/report-result/:user"}
            element={<ReportsUser />}
          />
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/report-result/:user/:task"}
            element={<TasksUser />}
          />
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/report-result/:user/:year/:month"}
            element={<MonthlyTasks />}
          />
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/new-muslims"}
            element={<NewMuslims />}
          ></Route>
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/graduated-students"}
            element={<KhirrijStudents />}
          ></Route>
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/blocked-students"}
            element={<StudentsBlocked />}
          ></Route>
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/channel"}
            element={<Channel />}
          ></Route>
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/setting"}
            element={<Setting />}
          ></Route>
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/about"}
            element={<About />}
          ></Route>
          <Route
            path={TITLE_ROUTE_SUPPORTER + "/questions"}
            element={<Quations />}
          ></Route>
        </Route>
      </Route>
      {/* catch all */}
      {/* <Route
        path="*"
        element={<Navigate replace to={TITLE_ROUTE_MOARIF + "/"} />}
      /> */}
    </Routes>
  );
}

export default App;
