import { memo, useState } from "react";
import { Container, Button } from "react-bootstrap";
import iconLink from "../../Layouts/images/home/link.png";
import iconImg from "../../Layouts/images/home/img.png";
import iconVideo from "../../Layouts/images/home/video.png";
import iconPlus from "../../Layouts/images/home/plus.png";
import { useLang } from "../../Utils/Context/LangContext";
import AddPost from "../home/AddPost";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";

const BoxAdd = () => {
  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState("");
  const [mediaType, setmediaType] = useState("");
  const handleShowAddPost = (icon) => {
    setShow(!show);
    setIcon(icon);
  };
  const handleCancelPost = (e) => {
    setShow(false);
  };
  const { t } = useLang();
  return (
    <>
      <div className="listCardForms">
        <Container>
          <div className="listCard" style={{ display: show ? "none" : "" }}>
            <Button
              variant="default"
              className="card-form card-link"
              onClick={() => {
                setmediaType("tweet");
                handleShowAddPost("tweet");
              }}
            >
              <div className="box-content-card">
                <img src={iconPlus} alt="" />
                <h4 className="title-card">{t("Add tweet")}</h4>
              </div>
              <div className="img-card">
                <img src={iconLink} alt="" />
              </div>
            </Button>
            <Button
              variant="default"
              className="card-form card-image"
              onClick={() => {
                setmediaType("photo");
                handleShowAddPost("picture-o");
              }}
            >
              <div className="box-content-card">
                <img src={iconPlus} alt="" />
                <h4 className="title-card">{t("Add Image")}</h4>
              </div>
              <div className="img-card">
                <img src={iconImg} alt="" />
              </div>
            </Button>
            <Button
              variant="default"
              className="card-form card-video"
              onClick={() => {
                setmediaType("video");
                handleShowAddPost("video-camera");
              }}
            >
              <div className="box-content-card">
                <img src={iconPlus} alt="" />
                <h4 className="title-card">{t("Add Video")}</h4>
              </div>
              <div className="img-card">
                <img src={iconVideo} alt="" />
              </div>
            </Button>
          </div>
          <AddPost
            mediaType={mediaType}
            show={show}
            icon={icon}
            handleCancelPost={handleCancelPost}
            handleShowAddPost={handleShowAddPost}
          />
        </Container>
      </div>
    </>
  );
};

export default memo(BoxAdd);
