import React from "react";
import CommunicationSpace from "../../../Components/comspace/communicationSpace";
import "./ComSpace.scss";

export default function Favorites() {
  return (
    <div className="com-space">
      <CommunicationSpace fav />
    </div>
  );
}
