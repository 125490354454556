import React, { useMemo } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { api } from "../../Utils/api";
import { useLang } from "../../Utils/Context/LangContext";
import useAuth from "../../Utils/Hooks/useAuth";
import { getCountries } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
const AccountSetting = ({ handleFormData, values, handleInputArray }) => {
  const countries = getCountries();
  const { updateUser } = useAuth();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await api.post(`user/update`, values);
      if (status === 201) {
        updateUser(data.user);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const { t } = useLang();
  return (
    <div className="accountSetting">
      <Form className="form-basic" onSubmit={handleSubmit}>
        <Row>
          <div className="col-md-12">
            <div className="search box-name">
              <Form.Control
                className="input-search"
                placeholder={t("name")}
                type="text"
                name="name"
                value={values.name}
                onChange={handleFormData("name")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="search box-mail">
              <Form.Control
                type="email"
                className="input-search"
                placeholder={t("Email")}
                name="email"
                value={values.email}
                onChange={handleFormData("email")}
              />
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="search box-country">
              <Form.Select
                aria-label="Default select example"
                name={"country"}
                className="input-search"
                onChange={handleFormData("country")}
                value={values.country}
              >
                <option>{t("Country")}</option>
                {countries.map((country) => (
                  <option value={country} key={country}>
                    {en[country]}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="search box-tel">
              <Form.Control
                type="tel"
                className="input-search"
                placeholder={t("Phone number")}
                name="phone_number"
                value={values.phone_number}
                onChange={handleFormData("phone_number")}
              />
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="search box-lang">
              <Form.Select
                aria-label="Default select example"
                className="input-search"
                onChange={handleFormData("language")}
                value={values.language}
              >
                <option>{t("Language")}</option>
                <option value="ar">{t("Arabic")}</option>
                <option value="fr">{t("French")}</option>
                <option value="en">{t("English")}</option>
                <option value="es">{t("Portugueuse")}</option>
              </Form.Select>
            </div>
          </div>
        </Row>
        <div className="action-btn">
          <Button variant="default" type="submit" className="btn-post">
            {t("Confirmation")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AccountSetting;
