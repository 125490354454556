import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import ItemTeam from "./ItemTeam";

import "./index.scss";
import { useState } from "react";
import { api } from "../../../Utils/api";
import HttpHandlerHoc from "../../../Utils/HOCS/httpHandlerHoc";

const ChooseTeams = () => {
  const [groups, setGroups] = useState([]);
  const [change, setChange] = useState(false);
  useEffect(() => {
    (async () => {
      const { data } = await api.get("group");
      console.log(data);
      setGroups(data.groups);
    })();
  }, [change]);
  return (
    <div className="chooseTeams">
      <div className="header-page header-center">
        <Container>
          <div className="content-header-page">
            <h2 className="title-page">Choose Team</h2>
            <div className="search">
              <input
                className="input-search"
                placeholder="search"
                type="text"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="list-items-teams">
        <>
          <Container>
            <Row className="row-items-teams">
              {groups.map((group) => (
                <ItemTeam team={group} key={group._id} setChange={setChange} />
              ))}
            </Row>
          </Container>
        </>
      </div>
    </div>
  );
};

export default ChooseTeams;
