import iconComment from "../../Layouts/images/space/plus.png";
import { Card, Button } from "react-bootstrap";
import { api } from "../../Utils/api";
import useAuth from "../../Utils/Hooks/useAuth";
import { useState } from "react";
const Post = ({ post, setChange }) => {
  const [affected, setAffected] = useState();
  const handleAddToFav = async () => {
    await api.put(`posts/like/${post._id}`);
    setChange((v) => !v);
  };
  const { user: currentUser } = useAuth();
  const currentUserId = currentUser._id || currentUser.id;
  async function affectLink() {
    await api.patch("/posts/affectlink/" + post._id);
    setChange((v) => !v);
    setAffected(true);
  }

  return (
    <Card>
      <Card.Body>
        <div className="content-post">
          <div className="user-post">
            <img
              style={{ width: 100, height: 100 }}
              src={post?.user?.profile_photo}
              alt=""
              className="img-user"
            />
            <div className="info-post">
              <Card.Title>{post?.user?.name}</Card.Title>
              <Card.Text>{post?.user?.role}</Card.Text>
            </div>
          </div>
          <p className="desc-post">{post?.title}</p>
          <p className="time-post">{post?.body}</p>
        </div>
      </Card.Body>
      <Card.Footer className="footer-space bg-white border-0">
        <p className="time-space">23h</p>
        <ul className="list-post-actions">
          <li>
            <Button variant="default" onClick={handleAddToFav}>
              {post?.likes.findIndex((e) => e.user === currentUserId) === -1 ? (
                <i
                  className="fa fa-heart-o"
                  style={{
                    fontSize: 45,
                    fontWeight: "bold",
                  }}></i>
              ) : (
                <i
                  className="fa fa-heart"
                  style={{
                    fontSize: 45,
                    fontWeight: "bold",
                  }}></i>
              )}
            </Button>
          </li>
          <li>
            <Button
              disabled={(post.affectedTo && post.affectedTo !== "") || affected}
              variant="default"
              onClick={() => {
                affectLink(post);
              }}>
              <img src={iconComment} alt="" />
            </Button>
          </li>
        </ul>
      </Card.Footer>
    </Card>
  );
};

export default Post;
