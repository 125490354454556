import React from 'react'
import { Container, Row } from 'react-bootstrap'
import ItemCertificate from './ItemCertificate';

const Certificate = () => {
  return (
    <div className='certificate dialogue content-grid'>
        <div className="listCertificate listDialogue">
            <Container>
                <div className='box-search'>
                        <h2 className='title-page'>List All Certificate</h2>
                        <div className='search'>
                            <input className='input-search' placeholder='search' type='text' />
                        </div>
                    </div>
                    <div className='list-box'>
                        <Row className='row-list-dialogue'>
                            {[...Array(20)].map(() => (
                                <ItemCertificate />
                            ))}
                        </Row>
                    </div>
            </Container>
        </div>
    </div>
  )
}

export default Certificate