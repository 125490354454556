import React, { memo, useState, useEffect } from "react";
import { Container } from "react-bootstrap";

import { api } from "../../Utils/api";
import { useLang } from "../../Utils/Context/LangContext";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import useAuth from "../../Utils/Hooks/useAuth";
import Post from "./Post";

const RecentPost = ({ all, mediaType, language, type = "all" }) => {
  const [posts, setposts] = useState([]);
  const [change, setChange] = useState(true);
  const { user: currentUser } = useAuth();
  const currentUserId = currentUser._id || currentUser.id;
  const user = all === true ? null : currentUserId;
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/posts", {
          params: { populateuser: true, user, mediaType, language, type },
        });
        setposts(data.posts);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [change, user, mediaType, language, type]);
  const { t } = useLang();
  return (
    <>
      <div className="recentPost">
        <Container>
          <h2 className="title-recent-post">{t("Recent post")}</h2>
          {posts.map((post) => (
            <div className="list-posts" key={post._id}>
              <Post post={post} setChange={setChange} />
            </div>
          ))}
        </Container>
      </div>
    </>
  );
};

export default memo(RecentPost);
