import { useEffect } from "react";
import { createContext, useState } from "react";
import { ADVOCATE, IDENTIFIER } from "../Constants";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user"))),
    [role, setrole] = useState(localStorage.getItem("role")),
    [token, settoken] = useState(localStorage.getItem("token")),
    themeColor = localStorage.getItem("theme"),
    subThemeColor = localStorage.getItem("subTheme"),
    [theme, setTheme] = useState(themeColor),
    [subTheme, setsubTheme] = useState(subThemeColor);
  function updateUser(user) {
    setuser(user);
    localStorage.setItem("user", JSON.stringify(user));
  }
  function updateAuth(token, user, role) {
    user && setuser(user);
    token && settoken(token);
    role && setrole(role);
    if (user == null) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("role");
    } else {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
    }
  }
  useEffect(() => {
    switch (role) {
      case IDENTIFIER[0]:
        setTheme("#208683");
        setsubTheme("#709e9c");
        localStorage.setItem("theme", "#208683");
        localStorage.setItem("subTheme", "#709e9c");
        break;
      case ADVOCATE[0]:
        setTheme("#2a27b1");
        setsubTheme("#00376F");
        localStorage.setItem("theme", "#2a27b1");
        localStorage.setItem("subTheme", "#00376F");
        break;
      default:
        setTheme("#CD8100");
        setsubTheme("#b1b40a");
        localStorage.setItem("theme", "#CD8100");
        localStorage.setItem("subTheme", "#b1b40a");
        break;
    }
  }, [role]);
  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        role,
        theme,
        setTheme,
        subTheme,
        updateAuth,
        updateUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
