import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { api } from "../../Utils/api";
import { useLang } from "../../Utils/Context/LangContext";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import { youtubeLinkToEmbed } from "../../Utils/transform";

const AddLesson = () => {
  const { t, language } = useLang();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDesc] = useState("");
  const { category, id } = useParams();
  const [lessons, setLessons] = useState([]);
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`courses/${category}/${id}`);
      setLessons(data.lessons);
    })();
  }, [id, category, changes]);
  const addLesson = async (e) => {
    e.preventDefault();
    try {
      await api.post(`courses/${category}/${id}`, {
        title,
        video: link,
        description,
      });
      setLink("");
      setTitle("");
      setDesc("");
      handleClose();
      setChanges(!changes);
      toast.success("lesson added succesfully");
    } catch (error) {
      toast.error("problem creating your lesson");
    }
  };

  return (
    <div className="courses dialogue content-grid">
      <div className="listCourses listDialogue">
        <Container>
          <div className="box-search">
            <h2 className="title-page">{t("Lessons")}</h2>
            <div className="d-flex gap-2">
              <div className={language === "ar" ? "searchv2" : "search"}>
                <input
                  className="input-search"
                  placeholder={t("search")}
                  type="text"
                />
              </div>
              <button
                className="btn btn-primary mx-4 btn-form-activity"
                onClick={handleShow}>
                {t("Add Lesson")}{" "}
              </button>
            </div>
          </div>
        </Container>
        <>
          <Container>
            <Row>
              {lessons.map((lesson) => (
                <Col sm={12} md={6} lg={4} key={lesson._id}>
                  <div
                    className="m-1"
                    style={{
                      backgroundColor: "#cd8100",
                      borderRadius: "10px",
                      padding: "10px",
                      height: "100%",
                    }}>
                    <Row className="video-container">
                      <iframe
                        className="media-dialogue"
                        width="100%"
                        height="100%"
                        src={youtubeLinkToEmbed(lesson.video)}
                        title={lesson.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                    </Row>
                    <div className="mt-2 " style={{ color: "white" }}>
                      <h5 className="text-truncate mb-3">{lesson.title}</h5>
                      <p className="text-truncate">{lesson.description}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      </div>
      <Form className="form-quiz quizItem p-2" onSubmit={addLesson}>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Add Lesson")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="text"
              placeholder={t("Lesson Title")}
              className="input-search"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              required={true}
            />
            <Form.Control
              type="text"
              placeholder={t("Lesson video link")}
              className="input-search my-4"
              onChange={(e) => setLink(e.target.value)}
              value={link}
              required={true}
            />
            <Form.Control
              type="text"
              placeholder={t("Lesson description")}
              className="input-search my-2"
              onChange={(e) => setDesc(e.target.value)}
              value={description}
              required={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button variant="primary" onClick={addLesson}>
              {t("Save Changes")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

export default AddLesson;
