import React from 'react';
import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'

const UpdateDescription = ({ showdesc, handleCloseEditDesc, description, onEditDescription }) => {
  const [desc, setdesc] = useState(description);
  const handlechangeInput = (e) => {
    setdesc(e.target.value);
  };
  return (
    <Modal className="updateDesc" show={showdesc} onHide={handleCloseEditDesc}>
      <Modal.Header closeButton className="text-center">
        <Modal.Title className="w-100 title-header">Your Description</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form-upload">
          <Form.Control as="textarea" rows={3} className="input-desc" placeholder="Add Description" value={desc} onChange={handlechangeInput} />
        </Form>
      </Modal.Body>
      <Modal.Footer className="action-btn m-0">
        <Button variant="default" className="btn-cancel" onClick={handleCloseEditDesc}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="btn-post"
          onClick={() => {
            handleCloseEditDesc();
            if (onEditDescription) onEditDescription(desc);
          }}
        >
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateDescription