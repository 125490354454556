import React from 'react'
import ListFavoriteTranslate from '../../../Components/favorites/listFavoriteTranslate';
import './Favorite.scss';

const FavoriteTranslators = () => {
  return (
    <div className='favorite'>
        <ListFavoriteTranslate />
    </div>
  )
}

export default FavoriteTranslators;