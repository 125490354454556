import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { getCountries } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import Input from "react-phone-number-input/input";
const Info = ({ nextStep, handleFormData, values }) => {
  // after form submit validating the form data using validator
  const [passwordc, setPasswordc] = useState("");
  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    if (values.name === "" || values.email === "" || values.password === "") {
      toast.error("fill all the blanks!");
    } else if (values.password !== passwordc) {
      toast.error("revise your password!");
    } else {
      nextStep();
    }
  };
  console.log(values);
  return (
    <div>
      <div className="info steps-register">
        <h3 className="title-form">Basic information</h3>
        <Form className="form-basic">
          <Row>
            <Col sm={12}>
              <div className="search box-name">
                <Form.Control
                  className="input-search"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleFormData("name")}
                />
              </div>
            </Col>
            <Col sm={12}>
              <div className="search box-mail">
                <Form.Control
                  type="email"
                  className="input-search"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleFormData("email")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <div className="search box-country">
                <Form.Select
                  aria-label="Default select example"
                  name="country"
                  className="input-search"
                  onChange={handleFormData("country")}
                >
                  {getCountries().map((country, index) => (
                    <option key={country} value={country}>
                      {en[country]}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>

            <Col md={6} sm={12}>
              <div className="search box-tel">
                <Input
                  international
                  withCountryCallingCode
                  countryCallingCodeEditable={false}
                  country={values.country}
                  placeholder="Enter phone number"
                  value={values.phone_number}
                  onChange={handleFormData("phone_number")}
                  name="phone_number"
                  className="input-search"
                  style={{ border: "none", width: "100%" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="search box-pass">
                <Form.Control
                  className="input-search"
                  placeholder="Password"
                  type="password"
                  onChange={handleFormData("password")}
                />
              </div>
            </Col>
            <Col>
              <div className="search box-pass">
                <Form.Control
                  type="password"
                  className="input-search"
                  placeholder="Confirmation"
                  value={passwordc}
                  onChange={(e) => setPasswordc(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="action-btn">
        <Button
          onClick={submitFormData}
          variant="default"
          type="submit"
          className="btn-next btn-step"
        >
          Next <i className="fa fa-angle-right"></i>
        </Button>
      </div>
    </div>
  );
};

export default Info;
