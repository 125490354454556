import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Modal, Row } from "react-bootstrap";
import { api } from "../../Utils/api";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import useAuth from "../../Utils/Hooks/useAuth";
import { toast } from "react-toastify";
import { useLang } from "../../Utils/Context/LangContext";
import { Link, useNavigate } from "react-router-dom";
import imgDialoge from "../../Layouts/images/dialogue.png";
import { transformDate } from "../../Utils/transform";

const Categories = () => {
  const [coursesCat, setCourses] = useState([]);
  const [title, setTitle] = useState("");
  const { user, role } = useAuth();
  const lang = user.language;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    api.get("courses/categories", { params: { lang } }).then((response) => {
      if (response.status === 200) {
        setCourses(response.data.categories);
      } else {
        toast.error("Couldnt get categories");
      }
    });
  }, [lang]);
  const { t, language } = useLang();
  const addCategory = () => {
    if (title === "") {
      toast.error("Please enter category name");
      return;
    }
    api.post("courses", { title, lang }).then((response) => {
      if (response.status === 201) {
        toast.success("Category added successfully");
        navigate(response.data.courseCategory._id);
      } else {
        toast.error("Couldnt add category");
      }
    });
  };
  console.log(role);

  return (
    <>
      <div className="courses dialogue content-grid">
        <div className="listCourses listDialogue">
          <Container>
            <div className="box-search">
              <h2 className="title-page">{t("Courses categories")}</h2>
              <div className="d-flex gap-2">
                <div className={language === "ar" ? "searchv2" : "search"}>
                  <input
                    className="input-search"
                    placeholder={t("search")}
                    type="text"
                  />
                </div>
                {role === "TEACHER" && (
                  <button
                    className="btn btn-primary mx-2 btn-form-activity"
                    onClick={handleShow}>
                    {t("add category")}{" "}
                  </button>
                )}
              </div>
            </div>
            <div className="list-box">
              <Row className="row-list-dialogue">
                {coursesCat.map(({ _id, title, createdAt }) => (
                  <div className="column-dialogue col-lg-6 col-md-6 col-sm-12">
                    <Card>
                      <Link to={_id}>
                        <Card.Img
                          className="media-dialogue"
                          variant="top"
                          src={imgDialoge}
                        />
                      </Link>
                      <Card.Body>
                        <div className="content-body">
                          <Link to={_id}>
                            <Card.Title>{title}</Card.Title>
                          </Link>
                          <p className="text-muted mt-2">
                            {transformDate(createdAt)}
                          </p>
                        </div>
                        <Link to={_id} className="btn btn-default btn-view">
                          <i className="fa fa-eye"></i>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </Row>
            </div>
          </Container>
        </div>
        <Form className="form-quiz quizItem p-2">
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{t("Add Lesson")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                placeholder={t("Category Title")}
                className="input-search"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="primary" onClick={addCategory}>
                {t("Save Changes")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      </div>
    </>
  );
};

export default Categories;
