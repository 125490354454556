import React from "react";
import { Link } from "react-router-dom";
import { useLang } from "../../../Utils/Context/LangContext";

const StatsCard = (props) => {
  const { t } = useLang();
  // console.log(props.number === "undefined" ? "false" : 'true');
  return (
    <Link to={props.link} className={`card-container ${props.name}`}>
      <h2 className="title">{props.title}</h2>
      <h4 className="number">{props.number}</h4>
      {props.name !== "channel" ? (
        <strong className="user">{t("User")}</strong>
      ) : (
        <strong className="user"> </strong>
      )}
      <i className="fa fa-plus-circle" />
      {/* <img src={props.image} alt={props.title} /> */}
    </Link>
  );
};

export default StatsCard;
