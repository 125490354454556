import React from "react";
import { Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import ImgTeam from "../../../Layouts/images/team.png";
import { api } from "../../../Utils/api";
import useAuth from "../../../Utils/Hooks/useAuth";

const ItemTeam = ({ team, setChange }) => {
  const { user } = useAuth();
  const userId = user._id || user.id;

  const joined = team?.participants?.findIndex((e) => e.id === userId) === -1;

  const handleAdd = async () => {
    api.put(`group/${team._id}`);
    setChange((v) => !v);
    toast.info(
      joined
        ? `you succesfully joined ${team.name}`
        : `you succesfully quit ${team.name}`,
      { autoClose: 1000 }
    );
    window.open(team.link, "_blank", "noopener,noreferrer");
  };
  return (
    <Col className="column-item-team col-md-6">
      <div className="item-team">
        <div className="content-item ">
          <img src={ImgTeam} alt="" className="img-item" />
          <h4 className="name-group">{team.name}</h4>
        </div>
        <div className="action-item">
          <Button className="btn-action" onClick={handleAdd}>
            {joined ? (
              <i className="fa fa-plus icon-btn"></i>
            ) : (
              <i className="fa fa-minus icon-btn"></i>
            )}
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default ItemTeam;
