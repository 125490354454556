// eslint-disable-next-line import/no-anonymous-default-export
export default {
  teacher: [
    {
      title: {
        ar: "الخريجين",
        fr: "étudian diplômés",
      },
      type: "number",
      name: "graduated",
    },
    {
      title: {
        ar: "عدد الخريجين هذا الشهر",
        fr: "number graduated this month",
      },
      type: "number",
      name: "number_graduated_this_month",
    },
    {
      title: {
        ar: "عدد مجموعات التعليم",
        fr: "number of teaching groups",
      },
      type: "number",
      name: "number_teaching_group",
    },
    {
      title: {
        ar: "المستوى الاول",
        fr: "élèves du premier degré",
      },
      type: "number",
      name: "first_grade_students",
    },
    {
      title: {
        ar: "المستوى الثاني",
        fr: "élèves de deuxième année",
      },
      type: "number",
      name: "second_grade_students",
    },
    {
      title: {
        ar: "المستوى الثالث",
        fr: "élèves du troisième degré",
      },
      type: "number",
      name: "third_grade_students",
    },
    {
      title: {
        ar: "العدد الفعلي معي الان",
        fr: "Nombre avec moi maintenant",
      },
      type: "number",
      name: "number_with_me",
    },
    {
      title: {
        ar: "من عمل لي حظر",
        fr: "Action d'interdiction",
      },
      type: "select",
      name: "ban_action",
      options: [
        {
          title: {
            ar: "اختر",
            fr: "selectioner une action...",
          },
          type: "",
          name: "",
        },
        {
          title: {
            ar: "لينك صفحه الفيس",
            fr: "lien facebook contact",
          },
          type: "text",
          name: "facebook_contact",
        },
        {
          title: {
            ar: "رقم هاتفه",
            fr: "Numéro de téléphone",
          },
          type: "number",
          name: "phone_number",
        },
        {
          title: {
            ar: "رقمه في جدول البيانات",
            fr: "Numéro dans le tableur",
          },
          type: "number",
          name: "database_number",
        },
      ],
    },
    {
      title: {
        ar: "العدد في الخاص",
        fr: "Nombre en privés",
      },
      type: "number",
      name: "number_in_private",
    },
    {
      title: {
        ar: "العدد في المجموعات",
        fr: "nombre en groupes",
      },
      type: "number",
      name: "number_in_groups",
    },
    {
      title: {
        ar: "العدد ال استلمه المعلم",
        fr: "number proffesor recu",
      },
      type: "number",
      name: "teacher_number_receive",
    },
    {
      title: {
        ar: "عدد المكالمات",
        fr: "nombre d'appels",
      },
      type: "number",
      name: "number_of_calls",
    },
    {
      title: {
        ar: "ملاحظات",
        fr: "commentaire",
      },
      type: "text",
      name: "comments",
    },
    {
      title: {
        ar: "اسم الطالب",
        fr: "Nom de l'étudiant",
      },
      type: "text",
      name: "student_name",
    },
    {
      title: {
        ar: "التاريخ",
        fr: "date",
      },
      type: "date",
      name: "date_creation",
    },
  ],
  daaiya: [
    {
      title: {
        ar: "عدد المحاورين ( المعرفين بالاسلام)",
        fr: "Nombre de personnes ayant reçu l'islam",
      },
      type: "links",
      name: "islam_receivers",
    },
    {
      title: {
        ar: "عدد المسلمين الجدد",
        fr: "Nombre de nouveaux musulmans",
      },
      type: "links",
      name: "new_muslims",
    },
    {
      title: {
        ar: "التاريخ",
        fr: "date",
      },
      type: "date",
      name: "date_creation",
    },
    // {
    //   title: {
    //     fr: "Nom de page",
    //     ar: "اسم الصفحة",
    //   },
    //   type: "text",
    //   name: "page_name",
    // },
    // {
    //   title: {
    //     fr: "Nombre de messages Aujourd'hui",
    //     ar: "عدد الرسائل اليوم",
    //   },
    //   type: "number",
    //   name: "messages_nbr",
    // },
    // {
    //   title: {
    //     fr: "Des pays",
    //     ar: "الدول",
    //   },
    //   type: "text",
    //   name: "countries",
    // },

    // {
    //   title: {
    //     ar: "سحب الفيس بوك",
    //     fr: "Facebook pull",
    //   },
    //   type: "links",
    //   name: "fb",
    // },
  ],
  moarif: [
    {
      title: {
        fr: "Number of partage",
        ar: "عدد المشاركين",
      },
      type: "number",
      name: "share",
    },
    {
      title: {
        fr: "Numéro de compte contacté",
        ar: "عدد الحسابات المتصلة",
      },
      type: "number",
      name: "contacted",
    },
    {
      title: {
        fr: "Nombre de hashtags",
        ar: "عدد الهاشتاقات",
      },
      type: "number",
      name: "hashtags",
    },
    {
      title: {
        fr: "Nombre de vues",
        ar: "عدد المشاهدات",
      },
      type: "number",
      name: "views",
    },
    {
      title: {
        fr: "Nombre de personnes devenues avocate",
        ar: "عدد الأشخاص الذين أصبحوا دعاة",
      },
      type: "number",
      name: "advocate",
    },
    {
      title: {
        fr: "Nombre de personnes converties à l'islam",
        ar: "عدد الأشخاص الذين أصبحوا مسلمين",
      },
      type: "number",
      name: "peopleConverted",
    },
    {
      title: {
        ar: "التاريخ",
        fr: "date",
      },
      type: "date",
      name: "date_creation",
    },
  ],
};
