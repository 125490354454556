import React from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLang } from "../../../Utils/Context/LangContext";
import { api } from "../../../Utils/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
const AskQ = () => {
  const [body, setBody] = useState("");
  const queryClient = useQueryClient();
  const { t } = useLang();

  const { isLoading, mutate, isError } = useMutation(
    () => {
      return api.post("inquiry", { body });
    },
    {
      onSuccess: () => {
        setBody("");
        toast.success("inquiry added succesfully");
        queryClient.invalidateQueries(["inquiries"]);
      },
      onError: () => {
        toast.error("problem creating your inquiry");
      },
    }
  );
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    toast.error("problem creating your inquiry");
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate();
  };
  return (
    <Form className="send-inquiry" onSubmit={handleSubmit}>
      <Form.Control
        type="text"
        rows={5}
        className="input-comment"
        placeholder={t("send inquiry")}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <Button
        type="submit"
        className="inquiry-send"
        outline
        style={{ boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)" }}>
        <i className="fa fa-paper-plane" aria-hidden="true"></i>
      </Button>
    </Form>
  );
};

export default AskQ;
