import {memo} from 'react'
import { Modal, Button } from 'react-bootstrap'
import imgConfirmation from "../../Layouts/images/global/confirmation.png";

const Confirmation = (props) => {
    const handleAddPost = (e) => {
        e.preventDefault();
        props.handleShowAddPost();
        // Add Post Data
    }
  return ( 
    <div className='confirmation'>
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='modal-confirmation'
        >
            <Modal.Body>
                <h4 className='title-confirmation'>Confirmation</h4>
                <img src={imgConfirmation} alt="" className='img-confirmation' />
                <p className='desc-confirmation'>
                    You want to share this post ? 
                </p>
                <div className="buttons-confirmation">
                    <Button 
                        variant='default' 
                        className='btn-cancel' 
                        onClick={props.onHide}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant='white' 
                        className='btn-success'
                        onClick={handleAddPost}
                    >
                        Ok
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default memo(Confirmation);