import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ImgUserAdv from "../../../Layouts/images/global/fav-user.png";
import ImgAdvStars from "../../../Layouts/images/advocacy.png";
import ConfirmationAddFav from "../../../Components/translators/ConfirmationAddFav";
import "./Advocacy.scss";
import { api } from "../../../Utils/api";
import HttpHandlerHoc from "../../../Utils/HOCS/httpHandlerHoc";
import useAuth from "../../../Utils/Hooks/useAuth";
import { useLang } from "../../../Utils/Context/LangContext";

const Advocacy = () => {
  const [show, setShow] = useState(false);
  const [advocacyCount, setAdvocacyCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const { user: boogie } = useAuth();
  const handleClose = () => {
      setSelectedUser("");
      setShow(false);
    },
    handleShow = (id) => {
      setSelectedUser(id);
      setShow(true);
    };
  const { updateUser } = useAuth();

  const handleAddToFav = async () => {
    const { data } = await api.put(`user/fav/${selectedUser}`);
    const user = data.user;
    updateUser(user);
  };
  useEffect(() => {
    (async () => {
      const { data: result } = await api.get("users/counter");
      const { advocate_count, data } = result;
      setAdvocacyCount(advocate_count[0].advocate_count);
      setUsers(data);
    })();
  }, []);
  const { favourites = [], id } = boogie;
  const { t } = useLang();

  return (
    <div className="advocacy">
      <div className="headerAdvocacy">
        <Container>
          <div className="content-header">
            <i className="fa fa-user icon-advocacy"></i>
            <h2 className="number-advocacy">{advocacyCount + 1}</h2>
            <h4 className="title-advocacy">{t("Advocacy")}</h4>
          </div>
        </Container>
      </div>
      <>
        <div className="listAdvocacy">
          <Container>
            <Row className="row-advocacy">
              {users.map((user, index) => (
                <Col className="column-advocacy col-12" key={user._id}>
                  <div className="card-advocacy px-5">
                    <h2 className="title-page text-center">{index + 1}</h2>
                    <div className="details-user">
                      <img src={user.profile_photo || ImgUserAdv} alt="user" />
                      <div className="info-user">
                        <h4 className="name-user">{user.name}</h4>
                        <p className="role-user">{t(user.role)}</p>
                      </div>
                    </div>
                    {index === 0 && (
                      <>
                        <div className="list-start me-2">
                          <img src={ImgAdvStars} alt="" />
                          <img src={ImgAdvStars} alt="" />
                          <img src={ImgAdvStars} alt="" />
                        </div>
                      </>
                    )}

                    {user._id !== id && (
                      <div className="action-advocacy">
                        <Button
                          variant="default"
                          onClick={() => handleShow(user._id)}>
                          {favourites?.findIndex((e) => e.id === user._id) ===
                          -1 ? (
                            <>
                              {t("Add to favorite")}
                              <i className="fa fa-heart-o icon-button"></i>
                            </>
                          ) : (
                            <>
                              {t("remove favorite")}
                              <i className="fa fa-heart icon-button"></i>
                            </>
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </>
      <ConfirmationAddFav
        show={show}
        onHide={handleClose}
        selectedUser={selectedUser}
        handleConfirm={handleAddToFav}
      />
    </div>
  );
};

export default Advocacy;
