import React, { useEffect, useState, useRef } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";

import ReportItem from "./ReportItem";
import ImgProfile from "../../../Layouts/images/default_user.png";
import "./Report.scss";
import * as _ from "lodash";
import { useParams } from "react-router-dom";
import FilterModal from "../../shared/filterModal";
import { handlePermissions, api } from "../../../Utils/api";
import { useLang } from "../../../Utils/Context/LangContext";

const Report = () => {
  const [users, setusers] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const { role } = useParams();
  const { t } = useLang();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users/all", {
          params: {
            accepted: true,
            role: role.toUpperCase(),
          },
        });
        setusers(data.data);
        setFiltredData(data.data);
      } catch (e) {
        console.log(e);
        handlePermissions(e);
      }
    })();
  }, [role]);

  const reportItems = filtredData.map((user) => (
    <ReportItem
      key={user._id}
      id={user._id}
      img={user.profile_photo || ImgProfile}
      name={user.name}
      role={user.role}
    />
  ));
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="report">
        <div className="header-page header-report">
          <Container>
            <div className="content-header-page">
              <h2 className="title-page">{t("التقرير")}</h2>
              {/* <Button variant="primary" onClick={() => setModalShow(true)}>
                Filter
              </Button> */}
            </div>
          </Container>
        </div>
        <div className="reportItem">
          <Container>
            <div className="content-report">
              <Row className="row-report">{reportItems}</Row>
            </div>
          </Container>
        </div>
      </div>
      <FilterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={users}
        setData={setFiltredData}
      />
    </>
  );
};

export default Report;
