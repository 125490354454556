import React, { memo } from 'react'
import { Container, Accordion } from 'react-bootstrap';

const Quations = () => {
  return (
    <div className='questions'>
      <Container>
        <div className="header-page">
          <h2 className='title-page'>Q&A</h2>
        </div>
        <div className="list-questions">
          <Accordion defaultActiveKey="0" style={{borderRadius: "20px"}}>
            {[...Array(8)].map((item, index) => (
              <Accordion.Item eventKey={`${index}`}>
                <Accordion.Header>Quation Number {index + 1} </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum.
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Container>
    </div>
  )
}

export default memo(Quations);