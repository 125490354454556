
import styles from "./widghtLg.module.css"

import { Table } from 'react-bootstrap';
import cal from "../img/calender.png"


const WidghtLg = ({dataLastMonthStat}) => {

    const getArabicMonth = () => {
        // Get the current date
        const currentDate = new Date();

        // Specify the options for formatting the date in Arabic
        const options = { month: 'long', locale: 'ar' };

        // Format the date using Intl.DateTimeFormat
        const arabicMonth = new Intl.DateTimeFormat('ar', options).format(currentDate);
        return arabicMonth;
    }

    return (
        <div className={styles.WidghtLg} id="WidghtLg">


            <div className={styles.container}>
                <table className="table" id={styles.table}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ backgroundColor: "#00b2e1", color: "white", textAlign: "center" }}></th>
                            <th scope="col" style={{ backgroundColor: "#00b2e1", color: "white", textAlign: "center" }}>	فرنسي</th>
                            <th scope="col" style={{ backgroundColor: "#00b2e1", color: "white", textAlign: "center" }}>برتغالي</th>
                            <th scope="col" style={{ backgroundColor: "#00b2e1", color: "white", textAlign: "center" }}>	الإجمالي</th>
                            <th scope="col" style={{ backgroundColor: "#00b2e1", color: "white", textAlign: "center" }}>	المجموع الكلى من بدايتنا 9/10/2022</th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        <tr className="table-light">
                            <th scope="row" style={{ backgroundColor: "  #d4d2e0", color: "#6f7886" }}>رسالتنا وصلت</th>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.newMuslim?.fr || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.newMuslim?.pt || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.newMuslim?.total || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.newMuslim?.allCounts || 0 }</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: "  #d4d2e0", color: "#6f7886" }}>عدد الذين تم تعريفهم بالإسلام</th>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.newMuslim?.fr || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.newMuslim?.pt || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.newMuslim?.total || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.newMuslim?.allCounts || 0 }</td>
                        </tr>
                        <tr className="table-light">
                            <th scope="row" style={{ backgroundColor: "  #d4d2e0", color: "#6f7886" }}>عدد الدخلين في الأسلام</th>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.newMuslim?.fr || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.newMuslim?.pt || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.newMuslim?.total || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.newMuslim?.allCounts || 0 }</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: "  #d4d2e0", color: "#6f7886" }}>عدد مجموعات تعلم المسلم الجديد</th>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.teacher?.fr || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.teacher?.pt || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.teacher?.total || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.teacher?.allCounts || 0 }</td>
                        </tr>
                        <tr className="table-light">
                            <th scope="row" style={{ backgroundColor: "  #d4d2e0", color: "#6f7886" }}>عدد الخرجين</th>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.khirrij?.fr || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.khirrij?.pt || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }}>{dataLastMonthStat?.khirrij?.total || 0 }</td>
                            <td style={{ backgroundColor: "#fff" }} >{dataLastMonthStat?.khirrij?.allCounts || 0 }</td>

                        </tr>
                    </tbody>
                </table>


                <div className={styles.blocCalender}>
                    <img src={cal} alt="" className={styles.calen} />
                    <p className={styles.titleCale}>التقرير الشهري<br />{ new Date().getFullYear() } لشهر { getArabicMonth() }</p>

                </div>

            </div>

        </div>
    )
}

export default WidghtLg