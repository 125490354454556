import React, { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { api } from "../../Utils/api";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import AddComment from "./AddComment";
import CommentItem from "./CommentItem";
const RecentComment = () => {
  const [comments, setComments] = useState([]);
  const { postId } = useParams();
  const [change, setChange] = useState(true);
  useEffect(() => {
    (async () => {
      const { data } = await api.get("comments", {
        params: { postId, populateuser: true },
      });
      setComments(data.comments);
    })();
  }, [postId, change]);
  return (
    <>
      <div className="recentComment">
        <Container>
          <div className="header-comment">
            <h2 className="title-page me-2">Recent Comments</h2>
            <h4 className="sub-title-comment mb-0">All Comments</h4>
          </div>
          <div className="list-comments">
            <div className="all-comments">
              {comments.map((comment) => (
                <CommentItem
                  key={comment._id}
                  comment={comment}
                  setChange={setChange}
                />
              ))}
            </div>
            <AddComment postId={postId} setChange={setChange} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default RecentComment;
