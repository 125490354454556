import React from "react";
import RecentPost from "../../../Components/global/RecentPost";
import BoxAdd from "../../../Components/global/BoxAdd";
import { TITLE_ROUTE_DAAIYA } from "../../../Utils/Constants";

export default function Home() {
  return (
    <div className="homepage">
      <BoxAdd />
      <RecentPost role={TITLE_ROUTE_DAAIYA} all={true} type={"post"} />
    </div>
  );
}
