import Quiz from "./Quiz";
import StepInfo from "./Info";
import StepEducation from "./Education";
import StepRoleUser from "./RoleUser";
const Steps = ({
  step,
  formData,
  nextStep,
  prevStep,
  handleInputData,
  handleInputArray,
  addQuiz,
}) => {
  switch (step) {
    case 1:
      return (
        <StepInfo
          nextStep={nextStep}
          handleFormData={handleInputData}
          values={formData}
        />
      );
    case 2:
      return (
        <StepEducation
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          values={formData}
          handleInputArray={handleInputArray}
        />
      );
    case 3:
      return (
        <StepRoleUser
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          values={formData}
        />
      );
    case 4:
      return <Quiz prevStep={prevStep} values={formData} />;

    default:
      return null;
  }
};

export default Steps;
