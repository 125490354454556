import {useState} from 'react'
import { Container, Button } from 'react-bootstrap'
import IconPlus from "../../Layouts/images/home/plus-hover.png";
import IconLink from "../../Layouts/images/home/link.png";
import FormAddLink from './FormAddLink';

export default function AddLink() {
    const [show, setShow] = useState(false);

    const handleShowAddPost = () => {
        setShow(!show);
    }
  return (
    
    <div className='boxAddLink'>
        <Container>
            <div className="content-add-link" style={{display: show ? "none" : ""}}>
                <Button className="btn-form-add-link" variant="default" onClick={handleShowAddPost}>
                    <img src={IconPlus} alt="" />
                    Add link
                </Button>
                <div className="icon-add-link">
                    <img src={IconLink} alt="" />
                </div>
            </div>
            <FormAddLink show={show} handleShowAddPost={handleShowAddPost} />
        </Container>
        
    </div>
  )
}
