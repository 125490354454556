import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useLang } from "../../../Utils/Context/LangContext";
import ReactAudioPlayer from "react-audio-player";

const ColSound = ({audio}) => {
  return (
    <td>
      {audio ? (
        <ReactAudioPlayer
          style={{ height: "40px" }}
          src={audio}
          controls
        />
      ) : null}
    </td>
  );
};

export default function ModalAudios({visible,handleClose,audios}){
  const { t } = useLang();
  
  return (
    <Modal show={visible} onHide={handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">{t("List of Audios")}</h4>
        <div className="d-flex flex-row justify-content-center gap-3 flex-wrap">
          {audios.map((audioSrc, index) => (
            <div>
              <ColSound
                audio={audioSrc}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
