import React, { useState, useEffect, memo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import logo from "../../Layouts/images/sidebar/logos.png";
import bars from "../../Layouts/images/sidebar/bars.png";
import useAuth from "../../Utils/Hooks/useAuth";
import routes from "../../Routes/ListRoutes";
import ButtonAddNewMuslim from "../../Views/Dashboard_Da3iya/ModalAdd/ButtonAddNewMuslim";
import ButtonAddNewGroup from "../../Views/Dashboard_Da3iya/ModalAdd/ButtonAddNewGroup";
import { useRoleFromLoc } from "../../Utils/transform";
import { useLang } from "../../Utils/Context/LangContext";
const Sidebar = () => {
  const { user, updateAuth } = useAuth();
  let [dropSidebar, setDropSidebar] = useState(false);
  const location = useLocation(),
    { theme, subTheme } = useAuth();
  const logoutUser = async (e) => {
    updateAuth(null, null, null);
    window.location.reload();
  };

  const changeNavbarColor = () => {
    if (window.innerWidth <= 991) {
      setDropSidebar(true);
    } else {
      setDropSidebar(false);
    }
  };

  useEffect(() => {
    changeNavbarColor();
  }, []);
  const loc = useRoleFromLoc();
  const { t } = useLang();
  return (
    <div
      className={`sidebar ${dropSidebar ? "closeSidebar" : ""}`}
      style={{ backgroundColor: theme }}>
      <div className="topSidbar">
        <Container>
          <Row className="align-items-center">
            <Col>
              <div className="content-logo">
                <Button
                  variant="default"
                  onClick={() => setDropSidebar(!dropSidebar)}
                  style={{border: '0'}}>
                  <img src={logo} alt="منصة بلاغ" className="logo" style={{width: '80px', height: '94px'}} />
                </Button>
                {/* <h2 className="title-logo">منصة بلاغ</h2> */}
              </div>
            </Col>
            <Col className="text-end">
              <Button
                variant="default"
                className="btn-dropBar"
                onClick={() => setDropSidebar(!dropSidebar)}>
                <img src={bars} alt="منصة-بلاغ" />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="info-user"
        style={{ backgroundColor: dropSidebar ? subTheme : "" }}>
        <Container>
          <Link
            to="/profile"
            className="content-info-user"
            style={{ backgroundColor: subTheme }}>
            <div className="logo-user">
              <img
                style={{ width: 70, height: 70 }}
                src={user?.profile_photo || "/profile.png"}
                alt=""
              />
            </div>
            <div className="details-user">
              <h4 className="name-user">{user?.name}</h4>
              <p className="role-user">{t(user?.role)}</p>
            </div>
          </Link>
        </Container>
      </div>
      <ul className="list-links">
        {routes
          .filter((route) => {
            return route.role.includes(user.role);
          })
          .map((link, index) => (
            <li
              className={`${location.pathname === link.path ? "active" : ""}`}
              key={index}>
              <Link to={link.path} className="link-sidebar">
                <div className="img-icon-list">
                  <img className="icon-link" src={link.src} alt="" />
                </div>
                <span className="title-link">{t(link.title)}</span>
              </Link>
            </li>
          ))}
        <li>
          <Button
            onClick={logoutUser}
            className="link-sidebar"
            variant="default">
            <div className="img-icon-list">
              <i className="fa fa-sign-out icon-logout"></i>
            </div>
            <span className="title-link">{t("Logout")}</span>
          </Button>
        </li>
        {loc === "daaiya" && (
          <>
            <li
              style={{
                flexDirection: "column",
                paddingInline: 30,
                marginBottom: 50,
              }}>
              <ButtonAddNewMuslim />
            </li>
            <li style={{ flexDirection: "column", paddingInline: 30 }}>
              <ButtonAddNewGroup />
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default memo(Sidebar);
