import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { api } from "../../../Utils/api";
import { useLang } from "../../../Utils/Context/LangContext";
import HttpHandlerHoc from "../../../Utils/HOCS/httpHandlerHoc";
import ImgUser from "../../../Layouts/images/global/fav-user.png";
import "./inquiry.scss";
import AskQ from "./AskQ";
import { useQuery } from "@tanstack/react-query";

const Inquiry = () => {
  const { t } = useLang();
  const {
    isLoading,
    isError,
    data: questions,
    error,
  } = useQuery(["inquiries"], async () => {
    const { data } = await api.get("/inquiry");
    return data.inquiries;
  });
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <>
      <Container>
        <h2 className="title-page me-2">{t("Inquiry")}</h2>
        <AskQ />
        <div className="inquiries-container">
          {questions.map(({ _id, question, answer }) => (
            <>
              {answer && (
                <div className="inquiry-container">
                  <div className="inquiry-message">
                    <img
                      className="answer-user-img"
                      src={answer.user?.profile_url || ImgUser}
                      alt={answer.user.name}
                    />
                    <p className="desc-user">{answer.body}</p>
                  </div>
                </div>
              )}
              <p className="inquiry-message ms-4 ps-4">{question.body}</p>
            </>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Inquiry;
