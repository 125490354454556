import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useLang } from "../../../Utils/Context/LangContext";

export default function ModalImages({visible,handleClose,images}){
  const { t } = useLang();
  
  return (
    <Modal show={visible} onHide={handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">{t("List of images")}</h4>
        <div className="d-flex flex-row justify-content-center gap-3 flex-wrap">
          {images.map((imageSrc, index) => (
            <div>
              <img
                src={imageSrc}
                style={{
                  width: "150px",
                  height: "150px",
                }}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
