import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
const ModalConfirm = ({ handleClose, show }) => {
  const navigate = useNavigate();
  const handleCloseModal = () => {
    navigate("/login");

    handleClose();
  };

  return (
    <div className="modalConfirm">
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          Profile under review, an email will be sent when you confirm your
          request.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn-modal"
            onClick={handleCloseModal}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
