import { Outlet } from "react-router-dom";
import { memo } from "react";
// Import Components Global
import Sidebar from "./Sidebar";
import HeaderPage from "./Header";
import FooterPage from "./Footer";
import ChatBox from "./ChatBox";

const Layouts = () => {
  return (
    <div className="appglobal">
      <Sidebar />
      <div className="app-content">
        <HeaderPage />
        <Outlet />
        {/* <FooterPage />
        <ChatBox /> */}
      </div>
    </div>
  );
};

export default memo(Layouts);
