import React from "react";
import { Container, Button } from "react-bootstrap";
import { useLocation } from "react-router";
import ImgSuspicions from "../../../Layouts/images/suspicions.png";

const RespondingDetails = () => {
  const location = useLocation();
  return (
    <div className="suspicionsDetails">
      <Container>
        <div className="header-suspicions">
          <h2 className="title-page">{location.state.title}</h2>
        </div>
        <div className="content-suspicionsDetails ">
          <img
            style={{ width: 500, height: 200 }}
            src={location.state.photo}
            alt=""
            className="img-suspicions"
          />
          <p
            className="text-suspicions"
            dangerouslySetInnerHTML={{ __html: location.state.body }}></p>
        </div>
      </Container>
    </div>
  );
};

export default RespondingDetails;
