import React, { memo } from "react";
import { Container } from "react-bootstrap";
import IconUser from "../../Layouts/images/global/fav-user.png";

const Notification = (props) => {
  return (
    <div className={`notification ${props.show ? "openNotification" : ""}`}>
      <h3 className="title-notification">Notification</h3>
      <div className="content-recent-notification content-notification">
        <Container>
          <h5 className="title-recent">Recent</h5>
          <div className="info-notification">
            <div className="details-user">
              <img src={IconUser} alt="" />
              <h4 className="name-user">Rami Ali</h4>
            </div>
            <p className="action-notification">has join your platform</p>
          </div>
        </Container>
      </div>
      <div className="content-earlier-notification content-notification">
        <Container>
          <h5 className="title-recent">Earlier</h5>
          {[...Array(6)].map((e, _i) => (
            <div className="info-notification" key={`notification ${_i}`}>
              <div className="details-user">
                <img src={IconUser} alt="" />
                <h4 className="name-user">Ziad Arbi</h4>
              </div>
              <p className="action-notification">has join your platform</p>
            </div>
          ))}
        </Container>
      </div>
    </div>
  );
};

export default memo(Notification);
