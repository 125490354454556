import React, { useState } from "react";
import { Button, Modal, Form, Dropdown, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "../../../Utils/api";
import { useLang } from "../../../Utils/Context/LangContext";

const ModalAddNewMuslim = (props) => {
  const [name, setName] = useState("");
  const [social, setSocial] = useState("");
  const [link, setLink] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { status } = await api.post("group", {
      name,
      social,
      link,
    });
    if (status === 201) {
      toast.success(`${name} has been succesfully added`);
    } else {
      toast.error(`we fail to add ${name} :(`);
    }
    props.handleClose();
  };
  const { t } = useLang();
  return (
    <Modal show={props.show} onHide={props.handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">{t("Add New Group")}</h4>
        <Form className="form-add" onSubmit={handleSubmit}>
          <div className="upload-photo"></div>
          <Row>
            <Form.Group controlId="formGridName" className=" col-md-6 mb-3">
              <Form.Control
                type="text"
                className="input-styled"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                placeholder={t("Name Group")}
              />
            </Form.Group>

            <Form.Group controlId="formGridSocial" className=" col-md-6 mb-3">
              <Form.Control
                onChange={(e) => setSocial(e.target.value)}
                value={social}
                type="text"
                placeholder={t("Social Group")}
                className="input-styled"
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              controlId="formGridPassword"
              className=" col-md-12 mb-3">
              <Form.Control
                onChange={(e) => setLink(e.target.value)}
                value={link}
                type="text"
                placeholder={t("Link Group")}
                className="input-styled"
              />
            </Form.Group>
          </Row>
          <div className="footer-modal">
            <Button
              variant="default"
              className="btn-return mx-4"
              onClick={props.handleClose}>
              {t("Return")}
            </Button>
            <Button variant="primary" className="btn-add mx-4" type="submit">
              {t("Add")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default ModalAddNewMuslim;
