import React from "react";
import { Button } from "react-bootstrap";
import ImgUser from "../../Layouts/images/global/fav-user.png";
import { toast } from "react-toastify";
import { api } from "../../Utils/api";
import useAuth from "../../Utils/Hooks/useAuth";

const CommentItem = ({ comment: { body, user, _id, likes }, setChange }) => {
  const handlelike = async (e) => {
    try {
      await api.put(`comments/${_id}`);
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
    setChange((v) => !v);
  };
  const { user: currentUser } = useAuth();
  const currentUserId = currentUser._id || currentUser.id;
  return (
    <div className="commentItem">
      <div className="details-user">
        <img
          src={user.profile_photo || ImgUser}
          alt="commenter"
          className="img-user"
        />
        <div className="info-user">
          <h4 className="name-user">{user.name}</h4>
          <p className="role-user">{user.role}</p>
          <p className="desc-user">{body}</p>
        </div>
      </div>
      <div className="action-btn">
        <Button variant="default" className="btn-favorit" onClick={handlelike}>
          {likes.findIndex((e) => e.user === currentUserId) === -1 ? (
            <i className="fa fa-heart-o icon-favorit"></i>
          ) : (
            <i className="fa fa-heart icon-favorit"></i>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CommentItem;
