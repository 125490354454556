import {useState} from 'react'
import { Container, Button } from 'react-bootstrap'
import ImgRemove from "../../Layouts/images/global/remove.png";
import ImgUserFav from "../../Layouts/images/global/fav-user.png";

import ConfirmationFavorite from "./ConfirmationFavorite";

const ListFavoriteTranslate = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false),
        handleShow = () => setShow(true);
  return (
    <div className='listFavoriteTranslate'>
        <Container>
            <div className='header-favorite'>
                <h2 className='title-page'>Favorite</h2>
                <div className='box-filter-favorite'>
                    <Button variant="default">
                        <i className='fa fa-filter icon-filter'></i>
                    </Button>
                    <div className='search'>
                        <input className='input-search' placeholder='search' type='text' />
                    </div>
                </div>
            </div>
            <div className='listTranslate'>
                <div className='row-favorite'>
                    {[...Array(24)].map(() => (
                        <div className="column-favorite">
                            <div className='card-trans'>
                                <div className='details-user'>
                                    <img src={ImgUserFav} alt="" />
                                    <div className='info-user'>
                                        <h4 className='name-user'>Naim</h4>
                                        <p className='role-user'>Translator</p>
                                    </div>
                                </div>
                                <div className='action-favorite'>
                                    <Button variant="default" onClick={handleShow}>
                                        <img className='img-remove-fav' src={ImgRemove} alt=""></img>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
        <ConfirmationFavorite show={show} onHide={handleClose}  />
    </div>
  )
}

export default ListFavoriteTranslate;