import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { api } from "../../Utils/api";
import { useNavigate } from "react-router";

import ConfirmationPost from "../home/ConfirmationPost";

const FormAddLink = ({ show, handleShowAddPost }) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [linkerr, setlinkerr] = useState("");
  const [descerr, setdescerr] = useState("");
  const [formdata, setFormdata] = useState({
    description: "",
    link: "",
  });
  const handleCancelPost = (e) => {
    handleShowAddPost();
    setFormdata((prevState) => ({
      ...prevState,
      description: "",
      link: "",
    }));
  };

  const handlechangeInput = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormdata((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  async function onSubmit() {
    const linlreg = new RegExp(
      "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
    );
    setdescerr(!formdata.description ? "Link description is required" : "");
    setlinkerr(
      !formdata.link
        ? "Link is required"
        : !linlreg.test(formdata.link)
        ? "Invalid link"
        : ""
    );
    if (!formdata.link || !formdata.description || !linlreg.test(formdata.link))
      return;
    try {
      await api.post("posts", {
        type: "link_space",
        title: formdata.description,
        body: formdata.link,
      });
      handleCancelPost();
      navigate(0);
    } catch (e) {}
  }

  return (
    <div className="addPost" style={{ display: show ? "" : "none" }}>
      <Form className="form-post">
        <Form.Control
          as="textarea"
          rows={2}
          placeholder="Add Description"
          className="input-desc"
          value={formdata.description}
          onChange={handlechangeInput("description")}
        />
        <span
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: 17,
            marginLeft: 20,
          }}>
          {descerr}
        </span>
        <Form.Control
          style={{ marginTop: 20 }}
          as="textarea"
          rows={2}
          type="url"
          placeholder="Add Link"
          className="input-desc"
          value={formdata.link}
          onChange={handlechangeInput("link")}
        />
        <span
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: 17,
            marginLeft: 20,
          }}>
          {linkerr}
        </span>
        <div className="action-btn">
          <Button
            varient="default"
            className="btn-cancel"
            onClick={handleCancelPost}>
            Cancel
          </Button>
          <Button varient="default" className="btn-post" onClick={onSubmit}>
            Add Link
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormAddLink;
