import React from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { api } from "../../Utils/api";
import { toast } from "react-toastify";

const AddComment = ({ postId, setChange }) => {
  const [body, setBody] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(`comments/${postId}`, {
        body,
      });
      setChange((v) => !v);
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };
  return (
    <Form className="addComment" onSubmit={handleSubmit}>
      <Form.Control
        as="textarea"
        rows={5}
        className="input-comment"
        placeholder="Add Comment"
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <div className="action-btn">
        <Button variant="default" className="btn-cancel">
          Cancel
        </Button>
        <Button type="submit" variant="default" className="btn-post">
          Comment
        </Button>
      </div>
    </Form>
  );
};

export default AddComment;
