import { useState } from "react";
import { Container, Button } from "react-bootstrap";
import RecentPost from "../global/RecentPost";
import { useLang } from "../../Utils/Context/LangContext";

export default function FilterPosts() {
  let languages = [
      {
        lang: "all",
      },
      {
        lang: "arabic",
      },
      {
        lang: "french",
      },
      {
        lang: "english",
      },
    ],
    socials = [
      {
        social: "all",
      },
      {
        social: "tweet",
      },
      {
        social: "video",
      },
      {
        social: "photo",
      },
    ],
    [social, setSocial] = useState("all"),
    [language, setLanguage] = useState("all"),
    [dropsocial, setDropsocial] = useState(false),
    [droplanguage, setDroplanguage] = useState(false);
  const { t } = useLang();

  return (
    <div className="filterPosts">
      <Container>
        <div className="filter-search">
          <h2 className="title-filter">{t("Introductory Material")}</h2>
          <div className="filter-social filter-drop">
            <div className="drop-down-filter">
              <Button
                variant="default"
                className="btn-drop"
                onClick={() => setDropsocial(!dropsocial)}>
                {social}
                <i className="fa fa-sort-down"></i>
              </Button>
              <ul
                className="list-socials"
                style={{ display: !dropsocial ? "none" : "block" }}>
                {socials &&
                  socials.map((socialfilter, index) => (
                    <li key={index}>
                      <Button
                        variant="default"
                        className="btn-choice-filter"
                        onClick={() => setSocial(socialfilter.social)}>
                        {t(socialfilter.social)}
                      </Button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="filter-lang filter-drop">
            <div className="drop-down-filter">
              <Button
                variant="default"
                className="btn-drop"
                onClick={() => setDroplanguage(!droplanguage)}>
                {t(language)}
                <i className="fa fa-sort-down"></i>
              </Button>
              <ul
                className="list-socials"
                style={{ display: !droplanguage ? "none" : "block" }}>
                {languages &&
                  languages.map((languagefilter, index) => (
                    <li key={index}>
                      <Button
                        variant="default"
                        className="btn-choice-filter"
                        onClick={() => setLanguage(languagefilter.lang)}>
                        {t(languagefilter.lang)}
                      </Button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <Button variant="default" className="btn-search">
            <i className="fa fa-search"></i>
          </Button>
        </div>
      </Container>
      <RecentPost all mediaType={social} language={language} type="post" />
    </div>
  );
}
