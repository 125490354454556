import {
  TITLE_ROUTE_DAAIYA,
  TITLE_ROUTE_MOARIF,
  TITLE_ROUTE_NEW_MUSLIM,
  TITLE_ROUTE_TEACHER,
  TITLE_ROUTE_SUPPORTER,
  ADVOCATE,
  IDENTIFIER,
  NEW_MUSLIM,
  TEACHER,
  SUPPORTER,
} from "../Utils/Constants";

import iconHome from "../Layouts/images/sidebar/home.png";
import iconIntroductory from "../Layouts/images/sidebar/introductory.png";
import iconResponding from "../Layouts/images/sidebar/responding.png";
import iconSpace from "../Layouts/images/sidebar/space.png";
import iconActivity from "../Layouts/images/sidebar/activity.png";
import iconCounter from "../Layouts/images/sidebar/counter.png";
import iconSetting from "../Layouts/images/sidebar/setting.png";
import iconAbout from "../Layouts/images/sidebar/translators.png";
import iconQuestion from "../Layouts/images/sidebar/question.png";
import iconDialoge from "../Layouts/images/sidebar/dialoge.png";
// import iconTranslators from "../Layouts/images/sidebar/translators.png";
import iconFilter from "../Layouts/images/sidebar/filter.png";
import iconCenter from "../Layouts/images/sidebar/center.png";
import iconTeams from "../Layouts/images/sidebar/teams.png";

const routes = [
  // Routes users Moaref
  {
    path: `/${TITLE_ROUTE_MOARIF}`,
    role: IDENTIFIER,
    title: "Home",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/introductory`,
    role: IDENTIFIER,
    title: "Introductory material",
    src: iconIntroductory,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/comspace`,
    role: IDENTIFIER,
    title: "Com.Space",
    src: iconSpace,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/activity`,
    role: IDENTIFIER,
    title: "Daily Activity",
    src: iconActivity,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/monthly-act`,
    role: IDENTIFIER,
    title: "Monthly Activity",
    src: iconActivity,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/advocacy`,
    role: IDENTIFIER,
    title: "Advocacy counter",
    src: iconCounter,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/setting`,
    role: IDENTIFIER,
    title: "Setting",
    src: iconSetting,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/about`,
    role: IDENTIFIER,
    title: "About",
    src: iconAbout,
  },
  {
    path: `/${TITLE_ROUTE_MOARIF}/questions`,
    role: IDENTIFIER,
    title: "Q&A",
    src: iconQuestion,
  },

  // Routes Users Daaiya

  {
    path: `/${TITLE_ROUTE_DAAIYA}`,
    role: ADVOCATE,
    title: "Home",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/dialogue`,
    role: ADVOCATE,
    title: "Dialogue methode",
    src: iconDialoge,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/suspicions`,
    role: ADVOCATE,
    title: "Responding to suspicions",
    src: iconResponding,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/comspace`,
    role: ADVOCATE,
    title: "Com.Space",
    src: iconSpace,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/inquiry`,
    role: ADVOCATE,
    title: "Inquiry",
    src: iconResponding,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/activity`,
    role: ADVOCATE,
    title: "daily Activity",
    src: iconActivity,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/monthly-act`,
    role: ADVOCATE,
    title: "Monthly Activity",
    src: iconActivity,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/advocacy`,
    role: ADVOCATE,
    title: "Advocacy counter",
    src: iconCounter,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/new-muslim`,
    role: ADVOCATE,
    title: "New Muslim",
    src: iconDialoge,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/setting`,
    role: ADVOCATE,
    title: "Setting",
    src: iconSetting,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/about`,
    role: ADVOCATE,
    title: "About",
    src: iconAbout,
  },
  {
    path: `/${TITLE_ROUTE_DAAIYA}/questions`,
    role: ADVOCATE,
    title: "Q&A",
    src: iconQuestion,
  },

  // Routes Users New Muslims

  {
    path: `/${TITLE_ROUTE_NEW_MUSLIM}`,
    role: NEW_MUSLIM,
    title: "Home",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_NEW_MUSLIM}/centers`,
    role: NEW_MUSLIM,
    title: "Islamic Centers",
    src: iconCenter,
  },
  {
    path: `/${TITLE_ROUTE_NEW_MUSLIM}/teams`,
    role: NEW_MUSLIM,
    title: "Choose team",
    src: iconTeams,
  },
  {
    path: `/${TITLE_ROUTE_NEW_MUSLIM}/courses`,
    role: NEW_MUSLIM,
    title: "Courses",
    src: iconFilter,
  },
  {
    path: `/${TITLE_ROUTE_NEW_MUSLIM}/setting`,
    role: NEW_MUSLIM,
    title: "Setting",
    src: iconSetting,
  },
  {
    path: `/${TITLE_ROUTE_NEW_MUSLIM}/about`,
    role: NEW_MUSLIM,
    title: "About",
    src: iconAbout,
  },
  {
    path: `/${TITLE_ROUTE_NEW_MUSLIM}/questions`,
    role: NEW_MUSLIM,
    title: "Q&A",
    src: iconQuestion,
  },

  // Routes users Teacher
  {
    path: `/${TITLE_ROUTE_TEACHER}`,
    role: TEACHER,
    title: "Home",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_TEACHER}/courses`,
    role: TEACHER,
    title: "Courses",
    src: iconIntroductory,
  },
  {
    path: `/${TITLE_ROUTE_TEACHER}/students`,
    role: TEACHER,
    title: "Students",
    src: iconResponding,
  },
  {
    path: `/${TITLE_ROUTE_TEACHER}/graduated-students`,
    role: TEACHER,
    title: "Graduated Students",
    src: iconResponding,
  },

  {
    path: `/${TITLE_ROUTE_TEACHER}/activity`,
    role: TEACHER,
    title: "Daily Activity",
    src: iconActivity,
  },
  {
    path: `/${TITLE_ROUTE_TEACHER}/monthly-act`,
    role: TEACHER,
    title: "Monthly Activity",
    src: iconActivity,
  },
  {
    path: `/${TITLE_ROUTE_TEACHER}/setting`,
    role: TEACHER,
    title: "Setting",
    src: iconSetting,
  },
  {
    path: `/${TITLE_ROUTE_TEACHER}/about`,
    role: TEACHER,
    title: "About",
    src: iconAbout,
  },
  {
    path: `/${TITLE_ROUTE_TEACHER}/questions`,
    role: TEACHER,
    title: "Q&A",
    src: iconQuestion,
  },
  // Routes users Supporter
  {
    path: `/${TITLE_ROUTE_SUPPORTER}`,
    role: SUPPORTER,
    title: "Home",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/report/advocate`,
    role: SUPPORTER,
    title: "Report Advocate",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/report/teacher`,
    role: SUPPORTER,
    title: "Report Teacher",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/new-muslims`,
    role: SUPPORTER,
    title: "New Muslims Data",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/graduated-students`,
    role: SUPPORTER,
    title: "Graduated Students",
    src: iconResponding,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/blocked-students`,
    role: SUPPORTER,
    title: "Blocked Students",
    src: iconResponding,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/channel`,
    role: SUPPORTER,
    title: "Documentation Channel",
    src: iconHome,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/setting`,
    role: SUPPORTER,
    title: "Setting",
    src: iconSetting,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/about`,
    role: SUPPORTER,
    title: "About",
    src: iconAbout,
  },
  {
    path: `/${TITLE_ROUTE_SUPPORTER}/questions`,
    role: SUPPORTER,
    title: "Q&A",
    src: iconQuestion,
  },
];

export default routes;
