import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TITLE_ROUTE_SUPPORTER } from "../../../Utils/Constants";

const ReportItem = (props) => {
  return (
    <Col sm={12} className="column-report col-md-6 col-lg-6" key={props.id}>
      <div className="box-user">
        <Link
          state={{
            name: props.name,
            img: props.img,
            id: props.id,
            role: props.role,
          }}
          to={`/${TITLE_ROUTE_SUPPORTER}/report-result/` + props.id}
          className="details-user"
        >
          <img
            className="img-profile"
            style={{ width: 100, height: 100 }}
            src={props.img}
            alt=""
          />
          <div className="info-user">
            <h4 className="name-user">{props.name}</h4>
            <p className="role-user">{props.role}</p>
          </div>
        </Link>
      </div>
    </Col>
  );
};

export default ReportItem;
