import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useLang } from "../../../Utils/Context/LangContext";

export default function ModalImages({visible,handleClose,images}){
  const { t } = useLang();
  
  return (
    <Modal show={visible} onHide={handleClose} className="modal-add">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="body-add">
        <h4 className="title-modal">{t("List of images")}</h4>
        <Row>
        {images.map((imageSrc, index) => (
          <Col style={{display:'inline-block'}}>
            <img
              src={imageSrc}
              width={150}
              height={150}
            />
          </Col>
        ))}
        </Row>
        
      </Modal.Body>
    </Modal>
  );
};
