import { memo } from "react";
import { Modal, Button } from "react-bootstrap";
import imgConfirmationFav from "../../Layouts/images/global/icon-fav.png";

const ConfirmationAddFav = (props) => {
  const handleConfirm = async () => {
    props.handleConfirm();
    props.onHide();
  };
  return (
    <div className="confirmation">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-confirmation">
        <Modal.Body>
          <h4 className="title-confirmation">Confirmation</h4>
          <img src={imgConfirmationFav} alt="" className="img-confirmation" />
          <p className="desc-confirmation">
            You want to add this user to your favorite liste ?
          </p>
          <div className="buttons-confirmation">
            <Button
              variant="default"
              className="btn-cancel"
              onClick={props.onHide}>
              Cancel
            </Button>
            <Button
              variant="white"
              className="btn-success"
              onClick={handleConfirm}>
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default memo(ConfirmationAddFav);
