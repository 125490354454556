import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { api } from "../../../Utils/api";
import { useLang } from "../../../Utils/Context/LangContext";
import HttpHandlerHoc from "../../../Utils/HOCS/httpHandlerHoc";
import useSearch from "../../../Utils/Hooks/useSearch";
import { clean } from "../../../Utils/transform";
import "./Responding.scss";

const Responding = () => {
  const [questions, setquestions] = useState([]);
  const [q, setQ] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/posts");
        setquestions(data.posts.filter((elt) => elt.type === "question"));
      } catch (e) {}
    })();
  }, []);
  const res = useSearch(q, questions);
  const { t } = useLang();
  return (
    <>
      <div className="responding">
        <Container>
          <div className="header-responding">
            <h2 className="title-page">{t("All Question and Suggestion")}</h2>
            <div className="search">
              <input
                className="input-search"
                placeholder={t("search")}
                type="text"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
          </div>
          <div className="list-responding h-50">
            {res.map((elt) => (
              <div className="box-responding">
                <div className="content-responding">
                  <h3 className="title-responding text-truncate">
                    {elt.title}
                  </h3>
                  <p
                    className="desc-responding"
                    dangerouslySetInnerHTML={{ __html: clean(elt.body) }}
                    style={{ height: 100, overflow: "hidden" }}></p>
                </div>
                <Link
                  to={"/daaiya/suspicions/" + elt._id}
                  state={elt}
                  variant="default"
                  className="btn-respond">
                  {t("Read More")}
                  <i className="fa fa-arrow-right mx-4"></i>
                </Link>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Responding;
