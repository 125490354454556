import iconComment from "../../Layouts/images/global/icon-comment.png";
import { Link } from "react-router-dom";
import { Card, Row, Button } from "react-bootstrap";

import { api } from "../../Utils/api";
import { clean, useRoleFromLoc } from "../../Utils/transform";

import useAuth from "../../Utils/Hooks/useAuth";
const Post = ({ post, setChange }) => {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false),
  //   handleShow = () => setShow(true);
  const handleAddToFav = async () => {
    await api.put(`posts/like/${post._id}`);
    setChange((v) => !v);
  };
  const loc = useRoleFromLoc();
  const { user: currentUser } = useAuth();
  const currentUserId = currentUser._id || currentUser.id;
  return (
    <Card>
      <Card.Body>
        <Row style={{ alignItems: "center" }}>
          <div className="col-md-6">
            <div className="content-post">
              <div className="user-post">
                <img
                  style={{ width: 100, height: 100 }}
                  src={post.user?.profile_photo || "/profile.png"}
                  alt=""
                  className="img-user"
                />
                <div className="info-post">
                  <Card.Title>{post.user?.name}</Card.Title>
                  <Card.Text>{post.user?.role}</Card.Text>
                </div>
              </div>
              {post.title !== "post" ? (
                <a className="desc-post" href={post.title}>
                  {post.title}
                </a>
              ) : null}
              <p className="desc-post">{clean(post.body)}</p>
              <p className="date-post">
                {new Date(post.createdAt).toLocaleDateString("en-US")}
              </p>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{
              display: post.type === "twitter" ? "none" : "",
            }}>
            {post.photo ? (
              <img src={post.photo} alt={"post"} className="img-post" />
            ) : post.video ? (
              <video width="560" height="315" src={post.video} controls></video>
            ) : (
              ""
            )}
          </div>
        </Row>
      </Card.Body>
      <Card.Footer className="footer-post bg-white border-0">
        <ul className="list-post-actions">
          <li>
            <Button
              variant="default"
              onClick={handleAddToFav}
              className="btn-action-post">
              {post.likes.findIndex((e) => e.user === currentUserId) === -1 ? (
                <i
                  className="fa fa-heart-o"
                  style={{ fontSize: 45, fontWeight: "bold" }}></i>
              ) : (
                <i
                  className="fa fa-heart"
                  style={{ fontSize: 45, fontWeight: "bold" }}></i>
              )}
            </Button>
          </li>
          <li>
            <Link
              className="btn btn-default btn-action-post"
              to={`/${loc}/comment/${post._id}`}>
              <img src={iconComment} alt="" />
            </Link>
          </li>
        </ul>
      </Card.Footer>
    </Card>
  );
};

export default Post;
