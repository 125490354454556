import React from 'react';
import ListTranslators from '../../../Components/translators/listTranslators';
import './Translators.scss';

const Translators = () => {
  return (
    <div className='translators'>
        <ListTranslators />
    </div>
  )
}

export default Translators;
