import React from "react";
import { Container, Table } from "react-bootstrap";
import { transformDate } from "../../../Utils/transform";
import ReactAudioPlayer from "react-audio-player";
import { api } from "../../../Utils/api";
import { toast } from "react-toastify";
import { useLang } from "../../../Utils/Context/LangContext";

const ColSound = ({ user }) => {
  return (
    <td>
      {user.chahada ? (
        <ReactAudioPlayer
          style={{ height: "40px" }}
          src={user.chahada}
          controls
        />
      ) : null}
    </td>
  );
};

const ItemStudent = ({ students,getData }) => {
  const { t } = useLang();
  const titles = [
    "#",
    "الاسم",
    "الدولة",
    "العمر",
    "الجنس",
    "الدين",
    "اللغة",
    "الداعية",
    "رقم الهاتف",
    "الفيسبوك",
    "الشهادة",
    "التاريخ",
    "",
  ];

  const blockUser = async (userId) => {
    try {
      const res = await api.put(`user/block-new-muslim/${userId}`);
      if (res.status === 200 || res.status === 201) {
        await getData();
        toast.success(t("User Blocked successfully"));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("Failed"));
    }
  }
  const unBlockUser = async (userId) => {
    try {
      const res = await api.put(`user/unBlock-new-muslim/${userId}`);
      if (res.status === 200 || res.status === 201) {
        await getData();
        toast.success(t("User UnBlocked successfully"));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("Failed"));
    }
  }
  return (
    <div className="column-dialogue">
      {/* <Card>
        <div>
          <Card.Img
            style={{ maxWidth: "150px", maxHeight: "150px" }}
            className="media-dialogue"
            variant="top"
            src={data.profile_photo || "/profile.png"}
          />
        </div>
        <Card.Body>
          <div className="content-body">
            <div>
              <Card.Title>{data.name}</Card.Title>
              <p className="info-country">{data.country}</p>
              <p className="info-age">
                Age: <strong>{data.age}</strong>
              </p>
              <p className="info-age">
                Gender: <strong>{data.gender}</strong>
              </p>
              <p className="info-age">
                Religion: <strong>{data.religion}</strong>
              </p>
              <p className="info-age">
                Language: <strong>{data.language}</strong>
              </p>
              <p className="info-age">
                Daiya: <strong>{data.referer}</strong>
              </p>
            </div>
          </div>
        </Card.Body>
      </Card> */}
      <Container>
        <Table striped bordered hover responsive>
          <thead>
            <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
              {titles.map((t) => (
                <th
                  style={{
                    fontSize: "0.7rem",
                  }}
                  className="text-center  "
                >
                  <p
                    className="text-truncate text-capitalize"
                    style={{
                      margin: "0 auto",
                    }}
                  >
                    {t}
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {students &&
              students.map((user, index) => {
                return (
                  <tr key={`muslim-${index}`}>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {index + 1}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.name}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.country}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.age}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.gender}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.religion}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.language}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.referer}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <a
                        href={`tel:+${user.phone_number}`}
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        <span
                          style={{
                            direction: "rtl",
                          }}
                        >
                          {user.phone_number}+
                        </span>
                      </a>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        الفيسبوك:{" "}
                        <button
                          style={{
                            background: "#d2e8ff",
                            height: 22,
                            width: 80,
                            borderRadius: 10,
                            border: "none",
                            fontSize: 14,
                            color: "gray",
                          }}
                        >
                          <a
                            href={user.fb_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Visit
                          </a>
                        </button>
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <ColSound user={user} />
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      <p
                        className="text-truncate"
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {user.date_creation
                          ? transformDate(user.date_creation)
                          : transformDate(user.createdAt)}
                      </p>
                    </td>
                    <td
                      style={{
                        fontSize: "0.7rem",
                      }}
                      className="text-center"
                    >
                      {user.status == 'allowed' ? (
                        <button
                          onClick={()=>blockUser(user._id)}
                          style={{
                            background: "#fca000",
                            minWidth: 150,
                            padding: 8,
                            borderRadius: 6,
                            border: "none",
                            fontSize: 14,
                            color: "#fff",
                          }}
                        >
                          {t("Block")}
                        </button>
                      ) : (
                          <button
                          onClick={()=>unBlockUser(user._id)}
                          style={{
                            background: "#0ac66f",
                            minWidth: 150,
                            padding: 8,
                            borderRadius: 6,
                            border: "none",
                            fontSize: 14,
                            color: "#fff",
                          }}
                        >
                          {t("UnBlock")}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default ItemStudent;
