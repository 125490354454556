import { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { api } from "../../Utils/api";
import useAuth from "../../Utils/Hooks/useAuth";
import UpdateDescription from "../../Views/Profile/UpdateDescription";
import UpdatePhoto from "../../Views/Profile/UpdatePhoto";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import { useLang } from "../../Utils/Context/LangContext";
const InfoProfile = () => {
  const { updateUser, user } = useAuth();
  const [show, setShow] = useState(false),
    [showdesc, setShowdesc] = useState(false);

  function onUpdatePhoto() {}
  async function onEdit(type, val) {
    const formData = new FormData();
    if (type == "description") formData.append("description", val);
    if (type == "profile_photo") formData.append("profile_photo", val);
    console.log(formData);
    try {
      const { data } = await api.post("user/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      updateUser(data.user);
    } catch (e) {}
  }
  function onEditDescription(desc) {
    if (desc) onEdit("description", desc);
  }
  function onEditFile(file) {
    if (file) onEdit("profile_photo", file);
  }
  const handleCloseEditPhoto = () => setShow(false),
    handleShowEditPhoto = () => setShow(true),
    handleCloseEditDesc = () => setShowdesc(false),
    handleShowEditDesc = () => setShowdesc(true);

  const { t } = useLang();
  return (
    <>
      <div className="infoProfile">
        <Container>
          <div className="card-profile">
            <Row className="row-profile">
              <Col className="column-info" md={6} sm={12}>
                <div className="details-profile">
                  <img
                    style={{ width: 70, height: 70, objectFit: "cover" }}
                    src={user.profile_photo || "/profile.png"}
                    alt="منصة بلاغ الدعوية"
                  />
                  <div className="info-user mx-4">
                    <h4 className="name-user m-0">{user.name}</h4>
                    <p className="role-user m-0">{t(user?.role)}</p>
                    <p className="desc-user m-0">{user.description}</p>
                  </div>
                </div>
              </Col>
              <Col className="column-action" md={6} sm={12}>
                <div className="actions-profile">
                  <Button variant="default" onClick={handleShowEditDesc}>
                    {t("Edit Profil description")}
                  </Button>
                  <Button variant="default" onClick={handleShowEditPhoto}>
                    {t("Edit photo")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <UpdatePhoto
          show={show}
          onEditFile={onEditFile}
          handleCloseEditPhoto={handleCloseEditPhoto}
        />
        <UpdateDescription
          onEditDescription={onEditDescription}
          showdesc={showdesc}
          handleCloseEditDesc={handleCloseEditDesc}
        />
      </div>
    </>
  );
};

export default InfoProfile;
