import React, { useState } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";
import { useEffect } from "react";
import { api } from "../../../Utils/api";
import { transformDate } from "../../../Utils/transform";
import { useLang } from "../../../Utils/Context/LangContext";
import axios from "axios";
import ModalImages from "./ModalImages";
import ModalAudios from "./ModalAudios";
import ModalVideos from "./ModalVideos";

const KhirrijStudents = () => {
  const [users, setUsers] = useState([]);
  const { t } = useLang();
  const [filtredData, setFiltredData] = useState([]);
  const [modalVisible, setModalVisible] = useState({
    images: false,
    videos: false,
    audios: false,
  });
  const [search, setSearch] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [isRequesting, setRequesting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);

  const titles = [
    "#",
    "الاسم",
    "الدولة",
    "تاريخ الاسلام",
    "صور",
    "فيديوهات",
    "صوتيات",
    "*******",
  ];

  // modals state
  const showModal = (type) => {
    if (type == "images") {
      setModalVisible({
        images: true,
        videos: false,
        audios: false,
      });
    }
    if (type == "videos") {
      setModalVisible({
        images: false,
        videos: true,
        audios: false,
      });
    }
    if (type == "audios") {
      setModalVisible({
        images: false,
        videos: false,
        audios: true,
      });
    }
  };
  const closeModal = () => {
    setModalVisible({
      images: false,
      videos: false,
      audios: false,
    });
  };


  // graduation api calls actions
  async function changeToGraduated(id) {
    setLoading(true);
    try {
      const response = await api.put(`khirrij/setGraduated/${id}`);
      if (response.status == 200 || response.status == 201) {
        await getData(1);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  async function changeToNotGraduated(id) {
    setLoading(true);
    try {
      const response = await api.put(`khirrij/setNotGraduated/${id}`);
      if (response.status == 200 || response.status == 201) {
        await getData(1);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  // get data
  async function getData(page) {
    setLoading(true);
    setRequesting(true);
    // Create a new cancel token for the API request
    const source = axios.CancelToken.source();
    try {
      const response = await api.get("khirrij/get-list-khirrij", {
        params: {
          search,
          page,
        },
        cancelToken: source.token, // Assign the cancel token to the request
      });
      const { data, currentPage, totalPages, totalUsers } = response.data;

      setFiltredData(data);
      setUsers(data);

      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalCount(totalUsers);
    } catch (error) {
      if (axios.isCancel(error)) {
        // Ignore canceled requests
        console.log("Request canceled", error.message);
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
      setRequesting(false);
    }
  }

  useEffect(() => {
    getData(currentPage);
    return () => {
      // Cancel the API request when the component unmounts
      if (cancelToken) {
        cancelToken.cancel("Request canceled");
      }
    };
  }, [currentPage, search]);

  // Pagination event handlers
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const usersPerPage = 10; // Number of users to display per page

  // Calculate the line number based on the current page and users per page
  const calculateLineNumber = (index) => {
    return (currentPage - 1) * usersPerPage + index + 1;
  };

  // Pagination event handlers
  const goToPreviousPage = () => {
    if (!isRequesting && currentPage > 1) {
      // Add check for isRequesting
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (!isRequesting && currentPage < totalPages) {
      // Add check for isRequesting
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="students education content-grid">
        <div className="listStudent listDialogue">
          <div className="header-page">
            <Container>
              <div style={{ flexWrap: "wrap" }} className="content-header-page">
                <h2 className="title-page">{t("Graduated Students")}</h2>
                <div className="d-flex gap-4 align-items-center">
                  <h4>المجموع: {totalCount}</h4>
                </div>
              </div>
            </Container>
          </div>
          <div className="list-box">
            <Container>
              <Form.Control
                type="text"
                placeholder={t("search")}
                className="input-styled "
                style={{ maxWidth: "350px", marginBottom: "20px" }}
                onChange={(e) => setSearch(e.target.value)}
              />

              <Table striped bordered hover responsive>
                <thead>
                  <tr style={{ backgroundColor: "#e3b25f", color: "white" }}>
                    {titles.map((t) => (
                      <th
                        style={{
                          fontSize: "0.7rem",
                        }}
                        className="text-center  "
                      >
                        <p
                          className="text-truncate text-capitalize"
                          style={{
                            margin: "0 auto",
                          }}
                        >
                          {t}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading
                    ? "loading"
                    : filtredData.map((user, index) => {
                        return (
                          <tr key={`muslim-${index}`}>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {calculateLineNumber(index)}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.name}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {user.country}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <p
                                className="text-truncate"
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                {transformDate(user.islamDate)}
                              </p>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <ModalImages
                                visible={modalVisible.images}
                                handleClose={closeModal}
                                images={user.images}
                              />
                              <button
                                onClick={() => showModal("images")}
                                style={{
                                  background: "#d2e8ff",
                                  height: 22,
                                  width: 80,
                                  borderRadius: 10,
                                  border: "none",
                                  fontSize: 14,
                                  color: "gray",
                                }}
                              >
                                {t("voir images")}
                              </button>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <ModalVideos
                                visible={modalVisible.videos}
                                handleClose={closeModal}
                                videos={user.videos}
                              />
                              <button
                                onClick={() => showModal("videos")}
                                style={{
                                  background: "#d2e8ff",
                                  height: 22,
                                  width: 80,
                                  borderRadius: 10,
                                  border: "none",
                                  fontSize: 14,
                                  color: "gray",
                                }}
                              >
                                {t("voir videos")}
                              </button>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              <ModalAudios
                                visible={modalVisible.audios}
                                handleClose={closeModal}
                                audios={user.audios}
                              />
                              <button
                                onClick={() => showModal("audios")}
                                style={{
                                  background: "#d2e8ff",
                                  height: 22,
                                  width: 80,
                                  borderRadius: 10,
                                  border: "none",
                                  fontSize: 14,
                                  color: "gray",
                                }}
                              >
                                {t("voir audios")}
                              </button>
                            </td>
                            <td
                              style={{
                                fontSize: "0.7rem",
                              }}
                              className="text-center"
                            >
                              {user.graduated ? (
                                <button
                                  onClick={()=>changeToNotGraduated(user._id)}
                                  style={{
                                    background: "#fca000",
                                    minWidth: 150,
                                    padding: 8,
                                    borderRadius: 6,
                                    border: "none",
                                    fontSize: 14,
                                    color: "#fff",
                                  }}
                                >
                                  {t("Make him not a graduated")}
                                </button>
                              ) : (
                                  <button
                                  onClick={()=>changeToGraduated(user._id)}
                                  style={{
                                    background: "#0ac66f",
                                    minWidth: 150,
                                    padding: 8,
                                    borderRadius: 6,
                                    border: "none",
                                    fontSize: 14,
                                    color: "#fff",
                                  }}
                                >
                                  {t("Make him a graduate")}
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
              <div className="pagination">
                <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
                  Previous
                </Button>
                <span>{`Page ${currentPage} of ${totalPages}`}</span>
                <Button
                  disabled={currentPage === totalPages}
                  onClick={goToNextPage}
                >
                  Next
                </Button>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default KhirrijStudents;
