import React from "react";
import { useState } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { api } from "../../Utils/api";
import { useLang } from "../../Utils/Context/LangContext";
import useAuth from "../../Utils/Hooks/useAuth";
const PasswordSetting = () => {
  const [password, setPassword] = useState("");
  const [nPassword, setNPassword] = useState("");
  const [nCPassword, setCNPassword] = useState("");
  const { updateUser } = useAuth();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (nCPassword !== nPassword) {
        throw new Error("password don't match");
      }
      const { data, status } = await api.post(`user/update`, {
        password,
        new_password: nPassword,
      });
      if (status === 201) {
        updateUser(data.user);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const { t } = useLang();
  return (
    <div className="passwordSetting accountSetting">
      <Form className="form-basic" onSubmit={handleSubmit}>
        <Row>
          <div className="col-md-12">
            <div className="search box-pass">
              <Form.Control
                className="input-search"
                placeholder={t("Your password")}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="search box-pass">
              <Form.Control
                className="input-search"
                placeholder={t("New Password")}
                type="password"
                onChange={(e) => setNPassword(e.target.value)}
                value={nPassword}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="search box-pass">
              <Form.Control
                type="password"
                className="input-search"
                placeholder={t("Confirmation password")}
                onChange={(e) => setCNPassword(e.target.value)}
                value={nCPassword}
              />
            </div>
          </div>
        </Row>
        <div className="action-btn">
          <Button variant="default" type="submit" className="btn-post">
            {t("Confirmation")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PasswordSetting;
