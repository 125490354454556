import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ImgUser from "../../../Layouts/images/user1.png";
import { transformDate } from "../../../Utils/transform";
import { api } from "../../../Utils/api";

import "./Report.scss";
export const getDay = (date) => {
  const ndate = new Date(date);
  return ndate.toLocaleDateString("fr").split("/")[0];
};
export const cleanNumber = (number) => {
  return number.replace(/^0+/, "").replace(/, +$/, "");
};
const TasksUser = () => {
  const [activity, setActivity] = useState([]);
  const { task } = useParams();
  useEffect(() => {
    (async () => {
      const { data } = await api.get(`activity/${task}`);
      setActivity(data);
    })();
  }, [task]);
  console.log(activity);
  return (
    <>
      <div className="tasksUser">
        <div className="header-page header-report">
          <Container>
            <div className="content-header-page">
              <div className="info-user">
                <img src={ImgUser} alt="" />
                <div className="info">
                  <h4 className="name-user mx-2">{activity?.owner?.name}</h4>
                  {/* <h5 className="mx-2">
                    {activity?.date_creation
                      ? transformDate(activity?.date_creation)
                      : transformDate(activity?.createdAt)}
                  </h5> */}
                </div>
              </div>
              {/* <Button variant="dafault" className="action-header">
                <i className="fa fa-trash icon-header"></i>
              </Button> */}
            </div>
          </Container>
        </div>
        <div className="reportsUser-items taksUser-item">
          <Container>
            <div className="content-report">
              <Row className="row-report">
                {activity?.activities?.map((task, index) => (
                  <Col className="column-report col-md-6">
                    <div className="box-user">
                      <div className="details-user">
                        <div className="info-user">
                          <h4 className="name-user text-capitalize">
                            {task.name}:
                          </h4>
                          <h5 className="mx-4 " style={{ color: "gray" }}>
                            {task.value}
                          </h5>
                        </div>
                      </div>
                      {/* <div className="action-report">
                        <p className="role-user">05/07/2021</p>
                      </div> */}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default TasksUser;
