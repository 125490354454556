import ItemCourse from "./ItemCourse";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import { api } from "../../Utils/api";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import useAuth from "../../Utils/Hooks/useAuth";
import { toast } from "react-toastify";
import { useLang } from "../../Utils/Context/LangContext";
import { useParams } from "react-router-dom";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { category } = useParams();
  const { user } = useAuth();
  const lang = user.language;
  useEffect(() => {
    api.get("courses/" + category).then((response) => {
      if (response.status === 200) {
        setCourses(response.data.courses);
      } else {
        toast.error("Couldnt get courses");
      }
    });
  }, [lang, category]);
  const { t, language } = useLang();
  const addCourse = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.post(`courses/${category}`, {
        title,
      });
      setTitle("");
      handleClose();
      console.log(data);
      setCourses(data.courses);
      toast.success("course added succesfully");
    } catch (error) {
      toast.error("problem adding your course");
    }
  };
  return (
    <>
      <div className="courses dialogue content-grid">
        <div className="listCourses listDialogue">
          <Container>
            <div className="box-search">
              <h2 className="title-page">{t("List Level Courses")}</h2>
              <div className="d-flex gap-2">
                <div className={language === "ar" ? "searchv2" : "search"}>
                  <input
                    className="input-search"
                    placeholder={t("search")}
                    type="text"
                  />
                </div>
                {user.role === "TEACHER" && (
                  <button
                    className="btn btn-primary mx-2 btn-form-activity"
                    onClick={handleShow}>
                    {t("add course")}{" "}
                  </button>
                )}
              </div>
            </div>
            <div className="list-box">
              <Row className="row-list-dialogue">
                {courses.map((course) => (
                  <ItemCourse course={course} key={course._id} />
                ))}
              </Row>
            </div>
          </Container>
        </div>
        <Form className="form-quiz quizItem p-2">
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{t("Add Lesson")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                placeholder={t("Course Title")}
                className="input-search"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="primary" onClick={addCourse}>
                {t("Save Changes")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      </div>
    </>
  );
};

export default Courses;
