import React, { memo, useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { useLang } from "../../Utils/Context/LangContext";
import useAuth from "../../Utils/Hooks/useAuth";
import AccountSetting from "./AccountSetting";
import PasswordSetting from "./PasswordSetting";

const Settings = () => {
  const {
    user: { name, email, country, phone_number, degree, language },
  } = useAuth();
  const [formData, setFormData] = useState({
    name: name || "",
    email: email || "",
    country: country || "",
    phone_number: phone_number || "",
    degree: degree || "",
    language: language || "",
    oldpassword: "",
    newpassword: "",
  });

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleInputArray = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: [...prevState.language, value],
    }));
  };
  const { t } = useLang();
  return (
    <div className="setting">
      <Container>
        <div className="header-page">
          <h2 className="title-page">{t("Setting")}</h2>
        </div>
        <Tabs
          defaultActiveKey="account"
          id="uncontrolled-tab-example"
          className="tabs-setting"
        >
          <Tab eventKey="account" title={t("Account")}>
            <AccountSetting
              handleFormData={handleInputData}
              values={formData}
              handleInputArray={handleInputArray}
            />
          </Tab>
          <Tab eventKey="password" title={t("Password")}>
            <PasswordSetting handleFormData={handleInputData} />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default memo(Settings);
