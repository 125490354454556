import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import IconFilter from "../../Layouts/images/space/filter.png";
import ConfirmationAddFav from "../translators/ConfirmationAddFav";
import { api } from "../../Utils/api";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import { useEffect } from "react";
import Post from "./Post";
import { toast } from "react-toastify";
import useAuth from "../../Utils/Hooks/useAuth";

export default function CommunicationSpace({ fav }) {
  const [posts, setposts] = useState([]);
  const [show, setShow] = useState(false);
  const [change, setChange] = useState(false);
  const { user } = useAuth();
  const userId = user._id || user.id;

  const handleClose = () => setShow(false),
    handleShow = () => setShow(true);
  useEffect(() => {
    const filter = (data) => {
      const links = data.filter((e) => e.type === "link_space");
      const likedLinks = links.filter(
        (e) => e.likes.findIndex((el) => el.user === userId) !== -1
      );
      console.log(likedLinks, userId);
      return fav ? likedLinks : links;
    };
    (async () => {
      try {
        const { data } = await api.get("/posts", {
          params: { populateuser: true },
        });
        setposts(filter(data.posts));
      } catch (e) {
        toast.error("something wrong!");
      }
    })();
  }, [change, fav, userId]);

  return (
    <>
      <div className="communicationSpace">
        <Container>
          <div className="header-communty">
            <h2 className="title-communication">
              {fav ? "Favorite Communication Space" : "Communication Space"}
            </h2>
            {/* <div className="filtre-communication">
              <p className="title-filtre">Filtre</p>
              <img src={IconFilter} alt="" />
            </div> */}
          </div>
          <div className="body-communication-space">
            <Row>
              {posts.map((post) => (
                <Col md={6} key={post._id}>
                  <Post setChange={setChange} post={post} />
                </Col>
              ))}
            </Row>
          </div>
        </Container>
        <ConfirmationAddFav show={show} onHide={handleClose} />
      </div>
    </>
  );
}
