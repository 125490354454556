import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useLang } from "../../../Utils/Context/LangContext";

import ModalAddNewGroup from "./ModalAddNewGroup";

const ButtonAddNewMuslim = () => {
  const [show, setShow] = useState(false);
  const { t } = useLang();
  const handleClose = () => setShow(false),
    handleShow = () => setShow(true);
  return (
    <>
      <Button
        variant="default"
        className="d-flex align-items-center justify-content-between w-100 add-special"
        style={{
          backgroundColor: "white",
          color: "#3FAEAB",
          position: "relative",
          height: "60px",
          paddingInlineStart: "60px",
          paddingInlineEnd: "40px",
          marginInline: "10px",
          borderRadius: "37px",
        }}
        onClick={handleShow}>
        <i
          className="fa fa-plus mx-2 d-flex align-items-center justify-content-center"
          style={{
            background: "#3FAEAB",
            color: "white",
            width: 60,
            height: 60,
            borderRadius: "50%",
            position: "absolute",
            right: -20,
          }}></i>
        <h4 className="title-btn-add">{t("New Group")}</h4>
      </Button>
      <ModalAddNewGroup handleClose={handleClose} show={show} />
    </>
  );
};

export default ButtonAddNewMuslim;
