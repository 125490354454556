import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import HttpHandlerHoc from "../../Utils/HOCS/httpHandlerHoc";
import { api } from "../../Utils/api";
import { useNavigate } from "react-router";
import { useLang } from "../../Utils/Context/LangContext";
import "../../Views/Dashboard_Da3iya/Activity/Activity.scss";
import { MONTHS } from "../../Utils/Constants";

const ListMonths = () => {
  const [activities, setActivities] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const { data } = await api.get("activity/month");
      setActivities(data);
    })();
  }, []);
  const { t, language } = useLang();
  return (
    <>
      <div className="listActivity">
        <Container>
          <Row>
            {activities?.map((activity, _i) => (
              <Col
                md={6}
                sm={12}
                key={`MonthlyActivity/${activity._id.year}/${activity._id.year}`}>
                <div className="box-activity">
                  <div className="content-activity" style={{ width: "70%" }}>
                    <h2 className="title-activity">
                      {t("Month")} {_i + 1}:{" "}
                      {MONTHS[language][activity._id.month - 1]}
                    </h2>

                    <p className="date-activity text-capitalize">
                      {t("work days number")}: {activity.total}
                    </p>
                  </div>
                  <div
                    className={
                      language === "ar"
                        ? "action-activity-ar"
                        : "action-activity"
                    }>
                    <Button
                      variant="default"
                      className="btn-action"
                      id="open-link"
                      onClick={() =>
                        navigate(`${activity._id.year}/${activity._id.month}`)
                      }>
                      <i
                        className="fas fa-arrow-right"
                        style={{ color: "white", fontSize: 30 }}></i>
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListMonths;
