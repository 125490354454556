import { Button, Row } from "react-bootstrap";
import useAuth from "../../Utils/Hooks/useAuth";
import { youtubeLinkToEmbed } from "../../Utils/transform";
import "./course.scss";
const Lesson = ({ lesson, dir, currentLesson, setCurrentV, index }) => {
  const { theme, subTheme } = useAuth();

  return (
    <Row
      className="p-2 rounded mb-3 align-items-center justify-content-center"
      style={{
        backgroundColor: currentLesson === lesson._id ? subTheme : theme,
        direction: dir,
      }}>
      <Row className="video-container">
        <iframe
          className="media-dialogue"
          width="100%"
          height="100%"
          src={youtubeLinkToEmbed(lesson.video)}
          title={lesson.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </Row>
      <div sm={6} className="mt-2 h-100" style={{ color: "white" }}>
        <h5 className="text-truncate">{lesson.title}</h5>
      </div>
      <Row sm={"auto"} className="align-self-center">
        <Button
          variant={currentLesson === lesson._id ? "secondary" : "primary"}
          disabled={currentLesson === lesson._id}
          onClick={() => setCurrentV(index)}>
          {currentLesson === lesson._id ? "Watching" : "Watch"}
        </Button>
      </Row>
    </Row>
  );
};

export default Lesson;
