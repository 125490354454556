import React from "react";
import { Container } from "react-bootstrap";
import IconPlus from "../../Layouts/images/home/plus.png";
import IconCalendar from "../../Layouts/images/global/calendar.png";
import { Link } from "react-router-dom";
import { useLang } from "../../Utils/Context/LangContext";

const BoxAddActivity = (props) => {
  const { t } = useLang();
  return (
    <div className="boxAddActivity">
      <Container>
        <div className="content-box-add-activity">
          <Link to="add" className="btn btn-default btn-form-activity">
            {t("Add activity")}
            <img src={IconPlus} alt="" className="img-plus mx-4" />
          </Link>
          <img src={IconCalendar} alt="" className="icon-calander" />
        </div>
      </Container>
    </div>
  );
};

export default BoxAddActivity;
