import React from "react";
import AddLink from "../../../Components/comspace/addLink";
import CommunicationSpace from "../../../Components/comspace/communicationSpace";
import "./ComSpace.scss";

export default function ComSpace() {
  return (
    <div className="com-space">
      <AddLink />
      <CommunicationSpace />
    </div>
  );
}
