import React from "react";
import { useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import AddInputActivity from "../../../Components/activity/AddInputActivity";
import { api } from "../../../Utils/api";
import { transformDate, useRoleFromLoc } from "../../../Utils/transform";
import LIST_LINKS from "./LIST_LINKS";
import "./Activity.scss";
import { useLang } from "../../../Utils/Context/LangContext";
import InputActivity from "../../../Components/activity/InputActivity";
import SelectActivity from "../../../Components/activity/SelectActivity";
import { toast } from "react-toastify";

export default function EditActivity() {
  const navigate = useNavigate();
  const {
    state: { updatedAt, activities, _id },
  } = useLocation();
  //reverse tje reduce function above
  const week2 = activities.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {});
  const [values, setfValues] = useState(week2);
  const [selectItem, setSelectItem] = useState(null);
  const handleSubmit = async () => {
    const exist = LIST_LINKS[ROLE].map((el) => {
      if (el.type === "select") {
        if (el.name == "ban_action") {
          const item = el.options.map((c) => {
            if (c.name == selectItem) {
              return values[c.name];
            }
          });
          return item;
        }
      } else {
        return values[el.name];
      }
    });
    if (
      exist.findIndex((el) => {
        if (Array.isArray(el)) {
          let res = el.find((l) => l !== "" && l !== undefined && l !== null);
          if (res) {
            return false;
          } else {
            return true;
          }
        } else {
          return el === "" || el === undefined;
        }
      }) !== -1
    ) {
      toast.error("fill the form first!");
      return;
    } else {
      const week = Object.entries(values).reduce((acc, [key, value]) => {
        acc.push({
          name: key,
          value,
        });
        return acc;
      }, []);
      const { data } = await api.put(`activity/${_id}`, week);
      console.log(data);
      navigate("/daaiya/activity");
    }
  };

  const setValues = (b) => {
    setfValues((v) => ({ ...v, ...b }));
  };
  const ROLE = useRoleFromLoc();
  const { t, language } = useLang();

  return (
    <div className="addActivity">
      <Container>
        <div
          className="w-100 d-flex align-items-center justify-content-between mb-5"
          style={{ height: 80 }}
        >
          <h5 className="p-0 m-0">Form</h5>
        </div>
        {activities.map(({ name, value }, index) => {
          const findI = LIST_LINKS[ROLE].findIndex((link) => {
            if (link.type === "select") {
              return link.options.find((e) => e.name === name);
            } else {
              return link.name === name;
            }
          });

          if (findI !== -1) {
            const { title, type, ...rest } = LIST_LINKS[ROLE][findI];
            if (type === "select") {
              const options = rest.options;
              return (
                <>
                  <SelectActivity
                    setSelectItem={setSelectItem}
                    selectItem={selectItem}
                    setfValues={setfValues}
                    key={`da3iya_${title[language]}_${index}`}
                    number={index + 1}
                    title={title[language]}
                    type={type}
                    name={name}
                    value={name}
                    options={options}
                  />
                  {selectItem && (
                    <div className="form-add-activity mx-4 my-4">
                      <div className="header-form">
                        <h4 className="title-add-link">
                          {index + 1}-{" "}
                          {
                            options.find(
                              (option) => option.name === selectItem
                            )["title"][language]
                          }
                        </h4>
                      </div>
                      <div className="box-input my-2">
                        <InputGroup
                          className="link-input"
                          key={`input control ${selectItem}`}
                        >
                          <Form.Control
                            name={`${selectItem}`}
                            type={
                              options.find(
                                (option) => option.name === selectItem
                              ).type
                            }
                            placeholder={t("Fill Info")}
                            style={{ height: 70 }}
                            defaultValue={value || ""}
                            required
                            onChange={(e) =>
                              setValues({ [selectItem]: e.target.value })
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                </>
              );
            } else {
              return (
                <InputActivity
                  setValues={setValues}
                  key={`da3iya_Edit_${title[language]}_${index}`}
                  number={index + 1}
                  title={LIST_LINKS[ROLE][findI].title[language]}
                  value={value}
                  type={type}
                  name={name}
                />
              );
            }
          }
          return null;
        })}
        <div className="w-100 mb-5 mt-4 d-flex justify-content-center">
          <button className="confirmationButton" onClick={() => handleSubmit()}>
            confirmation
          </button>
        </div>
      </Container>
    </div>
  );
}
