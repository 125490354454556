import { useLocation } from "react-router";
import sanitizeHtml from "sanitize-html";

export const transformDate = (date) => {
  const ndate = new Date(date);
  return ndate.toLocaleDateString("fr");
};
export const useRoleFromLoc = () => {
  const location = useLocation();
  return location.pathname.split("/")[1];
};
export const countryPhone = {
  "Saudi Arabia": "+966",
  Emirate: "+971",
  Kuwait: "+965",
};
export const roleMap = {
  IDENTIFIER: "moarif",
  ADVOCATE: "daaiya",
  ADMIN: "daaiya",
  USER: "muslim",
  TEACHER: "teacher",
  NEW_MUSLIM: "muslim",
  SUPPORTER: "supporter",
};
export const clean = (dirty) =>
  sanitizeHtml(dirty, {
    allowedTags: ["b", "i", "em", "strong", "a"],
    allowedAttributes: {
      a: ["href"],
    },
    allowedIframeHostnames: ["www.youtube.com"],
  });

export const getDay = (date) => {
  const ndate = new Date(date);
  return ndate.toLocaleDateString("fr").split("/")[0];
};
//remove 0 from the start of the string and , from the end
export const cleanNumber = (number) => {
  return number.replace(/^0+/, "").replace(/, +$/, "");
};
export const youtubeLinkToEmbed = (link) => {
  const youtubeId = link.split("v=")[1];
  console.log(link);
  return `https://www.youtube.com/embed/${youtubeId}`;
};
export const LANG = {
  ar: "Arabic",
  fr: "Frensh",
  en: "English",
  es: "Spanish",
  filipino: "Filipino",
  sw: "Swahili",
  pt: "Portugueuse",
  eg: "Egypt",
  tn: "Tunisia",
  au: "Australia",
  ma: "Morocco",
  mz: "Mozambique"
};
